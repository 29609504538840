import React, { useRef } from 'react'

import { Link } from 'gatsby'
import { useOnClickOutside } from 'hooks'
import { Wrapper, DropdownLink } from './style'
import { useMagic } from 'stores'

const Dropdown = ({ setDropdown }: { setDropdown: (_: boolean) => void }) => {
  const ref = useRef(document.createElement('div'))
  useOnClickOutside(ref, () => setDropdown(false))
  const { signOut } = useMagic()

  return (
    <Wrapper ref={ref} data-testid="dropdown-menu">
      <Link to="/app/dashboard">
        <DropdownLink>Dashboard</DropdownLink>
      </Link>
      <DropdownLink style={{ color: '#e81e61' }} onClick={() => signOut()}>
        Logout
      </DropdownLink>
    </Wrapper>
  )
}

export default Dropdown
