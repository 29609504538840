/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import { ThemeProvider } from 'styled-components'
import { ApolloProvider } from '@apollo/client'
import { identifyUser, useKlaviyo } from '@frontend-sdk/klaviyo'
import { IntercomProvider } from 'react-use-intercom'

import { client } from './src/services/apollo'
import { theme } from './src/style'
import { Banner, CallbackLoader } from './src/components'
import { useMagic, } from './src/stores'

const SessionCheck = ({ children }) => {
  const { isMagicInitialised, setSession, refreshToken, magic, user } = useMagic()
  useKlaviyo('TbXT7n')
  useEffect(() => {
    setSession()
  }, [])

  useEffect(() => {
    setInterval(() => {
      refreshToken()
    }, 840000)
  }, [])

  useEffect(() => {
    if (isMagicInitialised) {
      magic.preload()
    }
  }, [])

  useEffect(() => {
    if (user) {
      identifyUser({ $email: user.email })
    }
  }, [user])

  if (!isMagicInitialised) {
    return <CallbackLoader />
  }
  return <>{children} </>
}

export const wrapRootElement = ({ element }) => {
  return (
    <IntercomProvider appId="t080vequ" autoBoot><ApolloProvider client={client}>
      <SessionCheck>
        <Helmet>
          <link
            href="https://fonts.googleapis.com/css?family=Merriweather:300|Muli:200|Nunito&display=swap"
            rel="stylesheet"
          />
        </Helmet>
        <ThemeProvider theme={theme}>
          <Banner
            data-testid="notification-banner"
          />
          {element}
        </ThemeProvider>
      </SessionCheck>

    </ApolloProvider></IntercomProvider>
  )
}
