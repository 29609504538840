import React, { useContext, useState } from 'react'
import { ThemeContext } from 'styled-components'
import { Link } from 'gatsby'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { Trail, animated } from 'react-spring/renderprops.cjs'
import { FiLock } from 'react-icons/fi'

import { Stack, Body } from 'components'
import { ChapterHeader, SectionLink, Component, Chevron } from './style'
import { convertTitle } from 'utils'
import { ChapterType } from 'models'

type Props = {
  chapter: ChapterType
  isCurrent: boolean
  bookTitle: string
  isSampleMode: boolean
}

export default function ChapterLink({
  chapter,
  isCurrent,
  bookTitle,
  isSampleMode
}: Props) {
  const theme = useContext(ThemeContext)
  const [isExpanded, setIsExpanded] = useState(isCurrent)

  const hasSampleSections =
    chapter.sections.filter(section => section.isAvailableAsSample).length > 0

  const sortedSections = chapter.sections.sort((a, b) => a.order - b.order)

  // TODO: Change colors of text
  return (
    <Component disabled={isSampleMode && !hasSampleSections}>
      <ChapterHeader
        disabled={isSampleMode && !hasSampleSections}
        isCurrent={isCurrent}
        isChapterNumber
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <Stack
          gridTemplateColumns={'auto min-content'}
          width={'100%'}
          alignItems={'center'}
        >
          <Stack
            gridTemplateColumns={'min-content auto'}
            gridColumnGap={'1rem'}
          >
            <Body
              m={0}
              color={theme.colors.primaryRed}
              fontWeight={isCurrent ? 'bold' : 'normal'}
            >
              {chapter.order < 10 && '0'}
              {chapter.order}.
            </Body>
            <Body m={0} fontWeight={isCurrent ? 'bold' : 'normal'}>
              {chapter.title}
            </Body>
          </Stack>
          {isSampleMode && !hasSampleSections ? (
            <FiLock color={theme.colors.greys.grey50} />
          ) : (
            <Chevron isExpanded={isExpanded}>
              <MdKeyboardArrowDown />
            </Chevron>
          )}
        </Stack>
      </ChapterHeader>
      {isExpanded && (
        <Trail
          native
          config={{ tension: 500, friction: 37 }}
          items={sortedSections}
          keys={item => item.id}
          from={{ opacity: 0, transform: 'translateX(-100%)' }}
          to={{ opacity: 1, transform: 'translateX(0%)' }}
        >
          {item => props => {
            return isSampleMode && !item.isAvailableAsSample ? (
              <animated.div style={props}>
                <SectionLink disabled>
                  <Body color={theme.colors.greys.grey50} m={0}>
                    {item.order < 10 && 0}
                    {item.order}.
                  </Body>
                  <Body m={0} color={theme.colors.greys.grey50}>
                    {item.title}
                  </Body>
                  <FiLock color={theme.colors.greys.grey50} />
                </SectionLink>
              </animated.div>
            ) : (
              <animated.div style={props}>
                <Link
                  to={`/app/book/${convertTitle(bookTitle)}/chapter${
                    chapter.order
                  }/${item.order}`}
                  key={item.id}
                >
                  <SectionLink>
                    <Body color={theme.colors.primaryRed} m={0}>
                      {item.order < 10 && 0}
                      {item.order}.
                    </Body>
                    <Body m={0}>{item.title}</Body>
                  </SectionLink>
                </Link>
              </animated.div>
            )
          }}
        </Trail>
      )}
    </Component>
  )
}
