import styled from 'styled-components'

import { Link, LinkWrapper, Pane } from './style'

const Tabs = styled.div``

Tabs.Link = Link
Tabs.LinkWrapper = LinkWrapper
Tabs.Pane = Pane

export default Tabs
