import fetch from 'cross-fetch'
import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client'

export const client = new ApolloClient({
  link: new HttpLink({
    uri: `https://graphql.contentful.com/content/v1/spaces/mzb5y2nr6bul/environments/master?access_token=fa56d1d2a86fe19b7c671ec075a5b46132f42f69312f7f5083396fc4f948e51e`,
    fetch
  }),
  cache: new InMemoryCache()
})

export default client
