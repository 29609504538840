import React, { useState } from 'react'
import { Link } from 'gatsby'
import { useTransition, animated } from 'react-spring'
import IconButton from '@material-ui/core/IconButton'
import { Container } from '@material-ui/core'
import { FaFacebookF, FaYoutube, FaInstagram } from 'react-icons/fa'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { FiX } from 'react-icons/fi'

import { useMagic } from 'stores'
import Item from './Item'
import {
  Top,
  Middle,
  Bottom,
  BurgerWrapper,
  SocialMediaWrapper,
  MenuWrapper
} from './style'
import { Body, Stack, Box, Button } from 'components'
import logo from 'images/logo.svg'
import Lsglogo from 'images/lsg_logo.png'
import ShapeBackground from './ShapeBackground'

export default function Menu() {
  const { isAuthenticated } = useMagic()
  const [isOpen, setIsOpen] = useState(false)
  const [isHover, setIsHover] = useState(false)
  const menuTransition = useTransition(isOpen, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <Stack gridTemplateColumns="repeat(2, 1fr)" data-testid="menu">
        <Body variant="sm" m={0} color="greys.grey60">
          Menu
        </Body>
        <BurgerWrapper
          onMouseOver={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          onClick={() => setIsOpen(!isOpen)}
          data-testid="burger-menu"
        >
          <Top isHover={isHover} />
          <Middle isHover={isHover} />
          <Bottom isHover={isHover} />
        </BurgerWrapper>

        {menuTransition.map(
          ({ item, key, props }) =>
            item && (
              <animated.div style={props} key={key}>
                <MenuWrapper data-testid="mega-menu">
                  <Box
                    position="absolute"
                    right="2rem"
                    top="2rem"
                    style={{ zIndex: 3 }}
                  >
                    <Button
                      variant="icon"
                      onClick={() => setIsOpen(false)}
                      data-testid="close-menu-btn"
                    >
                      <FiX />
                    </Button>
                  </Box>
                  <Container>
                    <Stack
                      gridTemplateColumns="repeat(2, 1fr)"
                      width="100%"
                      alignItems="center"
                    >
                      <ShapeBackground />
                      <Stack gridRowGap={4}>
                        <a
                          href="https://vcestudyguides.com"
                          style={{ opacity: '60%' }}
                        >
                          <img style={{ height: '3rem' }} src={Lsglogo} />
                        </a>
                        <Stack gridRowGap={6}>
                          <Item href="https://vcestudyguides.com">Home</Item>
                          <Item href="https://www.vcestudyguides.com/blogs">
                            Blogs
                          </Item>
                          <Item
                            // as="a"
                            href="https://www.vcestudyguides.com/private-tutoring"
                          >
                            Private Tutoring
                          </Item>
                        </Stack>
                      </Stack>
                      <Stack gridRowGap={4}>
                        <Link
                          to="/"
                          onClick={() => setIsOpen(false)}
                          style={{ opacity: '60%' }}
                        >
                          <img src={logo} />
                        </Link>
                        <Stack gridRowGap={6}>
                          {isAuthenticated ? (
                            <Item
                              to="/app/dashboard"
                              as={Link}
                              onClick={() => setIsOpen(false)}
                            >
                              Dashboard
                            </Item>
                          ) : (
                            <Item
                              to="/signin"
                              component={Link}
                              onClick={() => setIsOpen(false)}
                            >
                              Login
                            </Item>
                          )}
                          <Item
                            to="/faq"
                            component={Link}
                            onClick={() => setIsOpen(false)}
                          >
                            FAQ
                          </Item>
                          <Item
                            to="/cart"
                            component={Link}
                            onClick={() => setIsOpen(false)}
                          >
                            Cart
                          </Item>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Container>
                  <SocialMediaWrapper>
                    <IconButton href="https://www.facebook.com/lisasstudyguides/">
                      <FaFacebookF />
                    </IconButton>
                    <IconButton href="https://www.youtube.com/channel/UCkH6-sgIxWjGb0kAaybyzew">
                      <FaYoutube />
                    </IconButton>
                    <IconButton href="https://www.instagram.com/lisasstudyguides/?hl=en">
                      <FaInstagram />
                    </IconButton>
                  </SocialMediaWrapper>
                </MenuWrapper>
              </animated.div>
            )
        )}
      </Stack>
    </ClickAwayListener>
  )
}
