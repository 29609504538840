import styled from 'styled-components'

export const Button = styled.div`
	border-radius: 100%;
	background: #2c2d30;
	position: relative;
	height: 2rem;
	width: 2rem;
	margin-left: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	color: white;
`

export default Button
