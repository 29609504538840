import React from 'react'
import Container from '@material-ui/core/Container'
import { FaHeart, FaReact } from 'react-icons/fa'

import { Component } from './style'
import lsgLogo from '../../images/lsg_logo.png'
import { Body } from 'components'

export default function Footer() {
	return (
		<Component>
			<Container maxWidth="md">
				<a href="https://vcestudyguides.com">
					<img src={lsgLogo} style={{ width: '15%' }} />
				</a>
				<Body color="white" fontSize="0.75rem" mt="1rem">
					ABN: 16 612 843 866
				</Body>
				<Body color="white" fontSize="0.75rem">
					Copyright © Lisa's Study Guides. All Rights Reserved. The VCAA does
					not endorse and is not affiliated with Lisa's Study Guides or
					vcestudyguides.com. The VCAA provides the only official, up to date
					versions of VCAA publications and information about courses including
					the VCE. VCE® is a registered trademark of the VCAA.
				</Body>
				<Body color="white" fontSize="0.75rem">
					© {new Date().getFullYear()}. Built with <FaHeart /> and <FaReact />
				</Body>
			</Container>
		</Component>
	)
}
