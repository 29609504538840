import * as React from 'react'
import { navigate } from 'gatsby'
import { Transition, config } from 'react-spring/renderprops.cjs'

import { Wrapper, Box, ExitButton } from './style'
import { useNotificationStore } from 'stores'

export default function Banner() {
  const [
    message,
    redirectUrl,
    closeNotification
  ] = useNotificationStore(state => [
    state.message,
    state.redirectUrl,
    state.closeNotification
  ])
  const handleClick = () => {
    if (redirectUrl) {
      navigate(redirectUrl)
      closeNotification()
    } else {
      closeNotification()
    }
  }

  return (
    <Transition
      items={!!message}
      config={config.gentle}
      from={{ transform: 'translate3d(-50%,50%,0)', opacity: 0 }}
      enter={{ transform: 'translate3d(-50%,0%,0)', opacity: 1 }}
      leave={{ transform: 'translate3d(-50%,50%,0)', opacity: 0 }}
    >
      {active =>
        active &&
        (style => (
          <Wrapper
            style={style}
            redirectUrl={redirectUrl}
            data-testid="notification"
          >
            <Box onClick={handleClick}>{message}</Box>
            <ExitButton onClick={closeNotification} />
          </Wrapper>
        ))
      }
    </Transition>
  )
}
