import styled from 'styled-components'
import { typography, layout, flexbox, space, color } from 'styled-system'

import { SectionProps } from './types'

const Section = styled.section<SectionProps>`
	${typography}
	padding: 5rem 0;
	${layout}
	${flexbox}
	${space}
	${color}

`
export default Section
