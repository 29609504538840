import styled from 'styled-components'
import media from 'styled-media-query'
import { flexbox } from 'styled-system'

type PaneProps = {
  value: number
  index: number
  showOverflow: boolean
}

type LinkProps = {
  active: boolean
}

export const Pane = styled.div<PaneProps>`
  margin-top: 1rem;
  display: ${props => (props.value !== props.index ? 'none' : 'flex')};
  justify-content: stretch;
  align-items: stretch;
  flex-direction: column;
  overflow: ${props => (props.showOverflow ? 'show' : 'scroll')};
  ${flexbox}
`

export const LinkWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, min-content);
  grid-column-gap: 1rem;
  ${media.between('small', 'medium')`
		width: 100%;
		overflow: scroll
	`}
  ${media.lessThan('small')`
		max-width: 100vw;
		overflow: scroll;
	`}
`

export const Link = styled.p<LinkProps>`
  cursor: pointer;
  padding: 1rem 1rem 1rem 0;
  opacity: 0.5;
  transition: all ease 0.3s;
  ${({ active }) =>
    active &&
    `
        border-bottom: 2px solid #e81e61;
        font-weight: bold;
        opacity: 1;
    `}
  &:hover {
    transform: scale(1.05);
  }
`

export default Link
