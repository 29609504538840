import CircularProgress from "@material-ui/core/CircularProgress"
import styled from "styled-components"

const CircularLoader = styled(CircularProgress)`
  &&& {
    color: ${props => props.theme.colors.blues.blue40};
    position: absolute;
    top: 50%;
    left: 50%;
    height: 2rem !important;
    width: 2rem !important;
    margin-top: -15px;
    margin-left: -15px;
  }
`
export default CircularLoader
