import styled from 'styled-components'

type WrapperProps = {
  darkMode: boolean
  onClick?: (_: any) => void
}

export const Wrapper = styled.div<WrapperProps>`
  border: 1px solid ${props => (props.darkMode ? 'white' : 'black')};
  opacity: 0.75;
  border-radius: 100px;
  padding: 6px;
  cursor: pointer;
  opacity: 0.2;
  /* position: absolute; */
  display: grid;
  position: relative;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  > i {
    margin: 0 auto;
  }
  transition: all ease-out 0.3s;
  &:hover {
    opacity: 1;
  }
`

export const Toggle = styled.div<WrapperProps>`
  height: 16px;
  width: 16px;
  border-radius: 100%;
  background-color: ${props => (props.darkMode ? 'white' : 'black')};
  transition: all 0.3s ease-out;
  margin-top: -2px;
  margin-bottom: -2px;
  transform: ${props =>
    props.darkMode ? 'translateX(110%)' : 'translateX(0%)'};
`
export const Icon = styled.div<WrapperProps>`
  transform: ${props =>
    props.darkMode ? 'translateX(-130%)' : 'translateX(0%)'};
  color: ${props => (props.darkMode ? 'white' : 'black')};
  padding-top: 3px;
  transition: all 0.3s ease-out;
  position: absolute;
  right: 4px;
  top: 1px;
`
