import styled from "styled-components"

const Indicator = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  bottom: 0.23rem;
  right: 0.23rem;
  border-radius: 100%;
  height: 0.5rem;
  width: 0.5rem;
  background-color: ${props => props.theme.colors.greens.green40};
`
export default Indicator
