import create from 'zustand'
import axios from 'axios'
import { client } from 'services/apollo'

type BookType = {
  sys: {
    id: string
  }
  title: string
  availability: string
  availabilityDate: string
  cover: {
    url: string
  }
}

type UserStoreProps = {
  userBooks: BookType[]
  loading: boolean
  setUserBooks: (
    user: { email: string; sessionToken: string },
    clearCache?: boolean
  ) => void
  cleanup: () => void
}
const useStore = create<UserStoreProps>(set => ({
  userBooks: [] as BookType[],
  loading: true,
  setUserBooks: async (user, clearCache = false) => {
    set({ loading: true })

    const { email, sessionToken } = user

    try {
      //get a users purchases
      const res = await axios({
        params: {
          email: email,
          clearCache: clearCache
        },
        url: '/.netlify/functions/getUsersBooks',
        headers: {
          authorization: sessionToken
        },
        method: 'get'
      })
      set({
        userBooks: res.data
      })
    } catch (err) {
      console.log(err)
    }

    set({ loading: false })
  },
  cleanup: async () => {
    await client.clearStore()
    await client.resetStore()
    set({ loading: true, userBooks: [] as BookType[] })
  }
}))
export default useStore
