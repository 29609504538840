import styled from "styled-components"
import { variant } from "styled-system"
import TextField from "@material-ui/core/TextField"

const Component = styled(TextField)`
  &&& {
    color: #3689d6;
    > &.MuiInput-underline:before {
      border-bottom-color: ${props => props.theme.colors.grey10};
    }
    & > .MuiInput-underline:after {
      border-bottom-color: ${props => props.theme.colors.blues.blue40};
    }
    & > .MuiInput-underline.Mui-error:after {
      border-bottom-color: ${props => props.theme.colors.error};
    }
    & > .MuiFormLabel-root.Mui-focused {
      color: ${props => props.theme.colors.blues.blue40};
    }
    & > .MuiFormLabel-root.Mui-error.Mui-focused {
      color: ${props => props.theme.colors.error};
    }
    & > .MuiFormLabel-root.Mui-error {
      color: ${props => props.theme.colors.error};
    }

    & > .MuiFormHelperText-root .Mui-error {
      color: ${props => props.theme.colors.error};
    }
  }
`
export default Component
