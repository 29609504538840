import styled from "styled-components"
import { space, layout, variant, border } from "styled-system"

import { T } from "./types"

const Pulse = styled.div`
  display: inline-block;
  height: 100%;
  width: 100%;
  background: linear-gradient(-90deg, #f0f0f0 0%, #f8f8f8 50%, #f0f0f0 100%);
  background-size: 400% 400%;
  animation: animate 1.2s ease-in-out infinite;
  @keyframes animate {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`

const Skeleton = styled(Pulse)<T>`
  height: 32px;
  border-radius: 10px;
  ${variant({
    variants: {
      rectangle: {},
      circle: {
        borderRadius: "100%",
        width: "32px"
      }
    }
  })};
  ${space};
  ${layout};
  ${border};
`

Skeleton.defaultProps = {
  variant: "rectangle"
}

export default Skeleton
