import styled from 'styled-components'
import { grid, flexbox, space, typography, layout } from 'styled-system'

import { StackProps } from './types'

const Stack = styled.div<StackProps>`
    display: grid;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    ${grid}
    ${flexbox}
    ${space}
    ${layout}
${typography}
`
export default Stack
