import * as React from 'react'

import { Box } from 'components'

const ShapeBackground = () => {
  return (
    <Box
      style={{
        transform: 'translateY(-50%)',
        position: 'absolute',
        left: '3rem',
        top: '50%',
        height: '500px'
      }}
    >
      <svg
        width="596"
        height="588"
        viewBox="0 0 596 588"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ position: 'absolute', top: 0 }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.34265 216.252C2.91479 266.719 17.1579 319.131 35.9741 366.036C75.4045 464.327 141.569 563.03 245.293 583.157C294.758 592.756 341.333 578.868 384.594 553.719C427.862 528.567 467.777 492.174 503.903 456.823C562.198 399.78 590.236 323.732 593.624 242.192C595.338 200.961 594.418 156.674 584.596 116.849C574.775 77.03 556.063 41.712 522.219 18.3559C490.289 -3.67935 454.475 -0.64006 416.857 9.18738C403.7 12.6246 390.347 16.8847 376.874 21.1834C371.083 23.0311 365.269 24.8859 359.439 26.6857C340.06 32.6678 320.519 38.0335 301.112 40.4559C278.314 43.3017 254.347 42.6436 230.34 41.174C222.87 40.7168 215.399 40.1812 207.958 39.6477C191.465 38.4655 175.117 37.2936 159.252 37.0077C113.212 36.1781 71.4868 42.8226 42.6944 78.1991C10.8677 117.304 -0.229391 165.789 1.34265 216.252ZM0.343132 216.283C-1.23394 165.658 9.89681 116.912 41.9188 77.5678C71.0178 41.8147 113.162 35.177 159.27 36.0078C175.163 36.2942 191.555 37.4693 208.062 38.6525C215.497 39.1855 222.955 39.7201 230.401 40.1759C254.394 41.6447 278.285 42.2975 300.989 39.4636C320.305 37.0525 339.777 31.7085 359.144 25.7301C364.954 23.9368 370.755 22.0857 376.541 20.24C390.026 15.9375 403.42 11.6642 416.604 8.21989C454.292 -1.62585 490.482 -4.76088 522.787 17.5329C556.896 41.0721 575.709 76.6421 585.567 116.61C595.423 156.573 596.338 200.972 594.624 242.234C591.227 323.977 563.112 400.284 504.602 457.538C468.47 492.895 428.48 529.365 385.097 554.584C341.708 579.807 294.884 593.799 245.103 584.139C140.84 563.907 74.4973 464.752 35.046 366.408C16.2031 319.437 1.92011 266.905 0.343132 216.283Z"
          fill="#F4B199"
        />
      </svg>

      <svg
        width="641"
        height="605"
        viewBox="0 0 641 605"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ position: 'absolute', top: 0 }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M362.844 4.42794C452.736 22.6005 464.367 140.573 516.744 215.855C561.317 279.921 648.59 324.186 639.218 401.667C629.303 483.633 548.606 534.038 473.982 569.366C400.128 604.33 319.632 614.899 241.879 589.772C145.68 558.684 38.5529 516.064 9.44872 419.246C-21.0475 317.797 35.7224 213.138 104.42 132.5C170.015 55.5047 263.702 -15.6147 362.844 4.42794Z"
          stroke="#FAD97F"
        />
      </svg>
    </Box>
  )
}

export default ShapeBackground
