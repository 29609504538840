import styled from "styled-components"
import { FiX } from "react-icons/fi"

import { WrapperProps } from "./types"

export const Box = styled.div`
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  background-color: ${props => props.theme.colors.blues.blue40};
  padding: 0.5rem 3rem;
`

export const Wrapper = styled.div<WrapperProps>`
  position: fixed;
  bottom: 10rem;
  z-index: 10;
  font-weight: bold;
  cursor: ${props => props.redirectUrl && "pointer"};
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: white;
`

export const ExitButton = styled(FiX)`
  position: absolute;
  cursor: pointer;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
`
