import React from 'react'
import { navigate } from 'gatsby'

import { useMagic } from 'stores'

const PrivateRoute = ({
  component: Component,
  ...rest
}: {
  component: React.ReactNode
}) => {
  const { isAuthenticated } = useMagic()

  if (!isAuthenticated) {
    // If the user is not logged in, redirect to the login page.
    if (typeof window !== 'undefined') {
      navigate('/signin')
    }
    return null
  } else {
    return <Component {...rest} />
  }
}

export default PrivateRoute
