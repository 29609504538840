import styled from "styled-components"
import { typography, space } from "styled-system"

const Header = styled.p`
  line-height: 1.25em;
  margin-bottom: 0.75em;
  font-weight: bold;
  ${space}
  ${typography}
`

export default Header
