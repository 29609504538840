import React, { useRef } from 'react'
import { Link } from 'gatsby'
import moment from 'moment'

import { convertTitle } from 'utils/helpers'
import { Body, Stack, Skeleton } from 'components'
import { Cover, CoverImg, Book, List } from './style'
import { BookType } from 'models'

type Props = {
  books: {
    sys: {
      id: string
    }

    availability: string
    availabilityDate: null | string
    cover: {
      url: string
    }
    title: string
  }[]
  loading: boolean
}

export default function BookList({ books, loading }: Props) {
  const skeletonRef = useRef(document.createElement('div'))

  if (loading) {
    return (
      <List data-testid="book-skeleton-loader">
        {Array.apply(0, Array(3)).map((e, i) => (
          <div key={i}>
            <Skeleton
              ref={skeletonRef}
              width="100%"
              height={
                skeletonRef ? skeletonRef.current.clientWidth * 1.25 : 250
              }
              mb="0.5rem"
            />
            <Skeleton width="100%" height="0.75rem" />
            <Skeleton width="50%" height="0.75rem" />
          </div>
        ))}
      </List>
    )
  }
  if (books.length === 0) {
    return <Body>Hmm...looks like you don't own any books yet</Body>
  }
  return (
    <List>
      {books.map(item => {
        return item.availability !== 'preorder' ? (
          <Book key={item.sys.id} data-testid="book">
            <>
              {typeof window !== 'undefined' &&
              !localStorage.getItem(item.title) ? (
                <Stack>
                  <Link to={`/app/book/${convertTitle(item.title)}/chapter1/1`}>
                    {process.env.NODE_ENV !== 'test'}
                    <Cover>
                      <CoverImg src={item.cover.url} />
                    </Cover>
                  </Link>
                  <Link to={`/app/book/${convertTitle(item.title)}/chapter1/1`}>
                    <Body mb={0}>
                      <strong>{item.title} </strong>
                    </Body>
                  </Link>
                </Stack>
              ) : (
                <Stack>
                  <Link to={localStorage.getItem(item.title)}>
                    <Cover>
                      <CoverImg src={item.cover.url} />
                    </Cover>
                  </Link>
                  <Link to={localStorage.getItem(item.title)}>
                    <Body Body mb={0}>
                      <strong>{item.title}</strong>
                    </Body>
                  </Link>
                </Stack>
              )}
            </>
          </Book>
        ) : (
          <Book key={item.sys.id} data-testid="book">
            <Stack>
              <Cover>
                <CoverImg src={item.cover.url} />
              </Cover>
              <Body mb={0}>
                <strong>{item.title}</strong>
              </Body>
              <Body color="greys.grey70">
                Book will automatically unlock{' '}
                {moment(item.availabilityDate).fromNow()}
              </Body>
            </Stack>
          </Book>
        )
      })}
    </List>
  )
}
