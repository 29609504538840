import React from 'react'
import styled from 'styled-components'
import ButtonBase from '@material-ui/core/ButtonBase'

type IndicatorProps = {
  mode: 'createAccount' | 'signIn'
}

export const Link = styled(({ active, ...other }) => <ButtonBase {...other} />)`
  && {
    cursor: ${props => (props.active ? 'auto' : 'pointer')};
    text-align: center;
    transition: all ease 0.3s;
    font-weight: ${props => (props.active ? 'bold' : 'normal')};
    color: ${props => (props.active ? 'white' : 'rgba(0,0,0,0.3)')};
    padding: 0.5rem 2rem;
    margin-bottom: 0;
    z-index: 2;
    padding: ${props => props.theme.space[2]}px 0;
    font-size: 1rem;
    border-radius: 100px;
  }
`

export const Indicator = styled.div<IndicatorProps>`
  border-radius: 100px;
  background-color: ${props => props.theme.colors.greys.grey40};
  position: absolute;
  left: 0;
  transition: all ease 0.3s;
  transform: ${props =>
    props.mode === 'createAccount' ? 'translateX(100%)' : 'translateX(0%)'};
  height: 100%;
  width: 50%;
`
export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  position: relative;
  border-radius: 100px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  margin-bottom: 2rem;
`

export default Link
