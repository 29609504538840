import React from 'react'
import styled from 'styled-components'
import Component from '@material-ui/core/Breadcrumbs'

import { Container } from 'components'
import { Wrapper } from './style'

export default function Breadcrumbs({
	children,
}: {
	children: React.ReactNode
}) {
	return (
		<Wrapper>
			<Container maxWidth="lg">
				<Component separator="›">{children}</Component>
			</Container>
		</Wrapper>
	)
}
