import React from 'react'
import Container from '@material-ui/core/Container'

import { Wrapper } from './style'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, ...props.initialState, errorInfo: '' }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.log('ERROR', errorInfo)
    this.setState({ ...this.state, errorInfo: errorInfo })
    // You can also log the error to an error reporting service
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Wrapper>
          <Container maxWidth="sm">
            <h2>
              <span>😕</span>
            </h2>
            <h3>Oh no, something has gone wrong!</h3>
            <p>
              But don't worry, we've already woken up all our engineers so they
              can get this problem fixed up for you promptly. In the meantime,
              try refreshing your page, or else try again later.
            </p>
          </Container>
        </Wrapper>
      )
    }

    return this.props.children
  }
}
export default ErrorBoundary
