export const ColourLuminance = (col: string, amt: number): string => {
  var usePound = false

  if (col && col[0] === "#") {
    col = col.slice(1)
    usePound = true
  }

  var num = parseInt(col, 16)

  var r = (num >> 16) + amt

  if (r > 255) r = 255
  else if (r < 0) r = 0

  var b = ((num >> 8) & 0x00ff) + amt

  if (b > 255) b = 255
  else if (b < 0) b = 0

  var g = (num & 0x0000ff) + amt

  if (g > 255) g = 255
  else if (g < 0) g = 0

  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16)
}

export const hexToRgb = (hex: string) => {
  // Check that it is hex and 8 chars (9 inc hash)
  if (hex && hex[0] === "#" && hex.length >= 7) {
    const hexColor = hex.slice(1, 7)
    const r = parseInt(hexColor.slice(0, 2), 16)
    const g = parseInt(hexColor.slice(2, 4), 16)
    const b = parseInt(hexColor.slice(4, 6), 16)

    if (hex.length === 9) {
      const hexAlpha = hex.slice(-2)
      const a = parseInt(hexAlpha, 16) / 255
      return `rgba(${r},${g},${b},${a})`
    } else {
      return `rgb(${r},${g},${b})`
    }
  } else {
    return hex
  }
}

export function recomposeColor(color: any) {
  const { type } = color
  let { values } = color

  if (type.indexOf("rgb") !== -1) {
    // Only convert the first 3 values to int (i.e. not alpha)
    values = values.map((n: any, i: number) => (i < 3 ? parseInt(n, 10) : n))
  } else if (type.indexOf("hsl") !== -1) {
    values[1] = `${values[1]}%`
    values[2] = `${values[2]}%`
  }

  return `${type}(${values.join(", ")})`
}

function clamp(value: number, min = 0, max = 1) {
  if (value < min) {
    return min
  }
  if (value > max) {
    return max
  }
  return value
}

export function decomposeColor(color: any): any {
  // Idempotent
  if (color.type) {
    return color
  }

  if (color.charAt(0) === "#") {
    return decomposeColor(hexToRgb(color))
  }

  const marker = color.indexOf("(")
  const type = color.substring(0, marker)

  if (["rgb", "rgba", "hsl", "hsla"].indexOf(type) === -1) {
    throw new Error(
      [
        `Material-UI: unsupported \`${color}\` color.`,
        "We support the following formats: #nnn, #nnnnnn, rgb(), rgba(), hsl(), hsla()."
      ].join("\n")
    )
  }

  let values = color.substring(marker + 1, color.length - 1).split(",")
  values = values.map((value: any) => parseFloat(value))

  return { type, values }
}

export function fade(color: any, value: number) {
  color = decomposeColor(color)
  value = clamp(value)

  if (color.type === "rgb" || color.type === "hsl") {
    color.type += "a"
  }
  color.values[3] = value

  return recomposeColor(color)
}
