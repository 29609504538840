import React, { useEffect } from 'react'
import { useIntercom } from 'react-use-intercom'

import { Heading, Container, Section, ErrorBoundary } from 'components'
import { useMagic, useUserStore } from 'stores'
import BookList from './BookList'

export default function Dashboard() {
  const { loading, userBooks, setUserBooks } = useUserStore()
  const { user } = useMagic()
  const { boot } = useIntercom()

  useEffect(() => {
    if (user) setUserBooks(user)
  }, [user])

  useEffect(() => {
    if (user) {
      boot({ email: user.email })
    }
  })

  return (
    <ErrorBoundary>
      <Section mt="5rem" data-testid="dashboard">
        <Container maxWidth="md">
          <Heading>All Your Books</Heading>
          <BookList books={userBooks} loading={loading} />
        </Container>
      </Section>
    </ErrorBoundary>
  )
}
