import styled from 'styled-components'
import media from 'styled-media-query'

export const Wrapper = styled.div`
	width: 100%;
	margin-bottom: 3rem;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 1rem;
	padding-bottom: 1rem;
	z-index: 5;
	> p {
		margin: 0;
	}
	${media.lessThan('medium')`
		top:-5rem;
		margin-right: 1rem;
	`}
	${media.lessThan('small')`
		top: -6rem;
	`}
`

export const ControlsWrapper = styled.div`
	display: grid;
	grid-auto-flow: column;
	align-items: center;
	grid-auto-columns: 1fr;
	grid-column-gap: 1rem;
`
