import styled from 'styled-components'

export const Wrapper = styled.div`
  position: absolute;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.25);
  top: 2.5rem;
  right: 0;
  z-index: 2;
  border-radius: 10px;
  background-color: white;
  color: #2c2d30;
  overflow: hidden;
`
export const DropdownLink = styled.div`
  padding: 1rem 3rem;
  transition: all ease 0.3s;
  text-align: center;
  &:hover {
    color: white;
    background-color: #2c2d30;
  }
`
