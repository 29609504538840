import styled from 'styled-components'
import { space, layout, position, flexbox, color } from 'styled-system'

import { BoxProps } from './types'
import { theme } from 'style'

const Box = styled.div<BoxProps>`
box-shadow: ${props => props.elevation && theme.shadows[props.elevation]};
border-radius: ${props => (props.rounded ? '10px' : 0)};
background-color:white;
  ${space}
  ${layout}
  ${position}
  ${flexbox}
  ${color}
`
export default Box
