import React, { useState } from 'react'

import { Component, Underline } from './style'

type Props = {
  children: React.ReactNode
  component?: any
  as?: React.ReactNode
  to?: string
  onClick?: (_: any) => void
  href?: string
}

export default function Item({
  children,
  component,
  to,
  onClick,
  href
}: Props) {
  const [isHover, setIsHover] = useState(false)

  return (
    <Component
      component={component}
      to={to}
      href={href}
      onClick={onClick}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {children}
      <Underline isHover={isHover} />
    </Component>
  )
}
