import React from 'react'
import { Link, navigate } from 'gatsby'

import { useCartStore, useMagic } from 'stores'
import { Container, Button, Stack } from 'components'
import CartButton from './CartButton'
import ProfileButton from './ProfileButton'
import { Wrapper } from './style'
import logo from '../../images/logo.svg'
import Menu from './Menu'

export default function Navbar() {
  const cart = useCartStore(state => state.cart)
  const { isAuthenticated } = useMagic()

  const getMaxWidth = () => {
    if (typeof window !== 'undefined') {
      if (window.innerWidth > 1280) {
        return 'lg'
      } else if (window.innerWidth > 1075) {
        return 'md'
      } else if (window.innerWidth > 715) {
        return 'sm'
      } else {
        return 'mobile'
      }
    }
  }

  return (
    <Wrapper data-testid="navbar">
      <Container maxWidth={getMaxWidth()}>
        <Stack gridTemplateColumns="min-content 1fr auto" alignItems="center">
          <Stack
            gridAutoFlow="column"
            gridTemplateColumns="repeat(2, max-content)"
            gridColumnGap="2rem"
            alignItems="center"
          >
            {getMaxWidth() !== 'mobile' ? (
              <Link to="/" data-testid="home-logo">
                <img src={logo} style={{ width: '90%' }} />
              </Link>
            ) : null}
            {isAuthenticated && (
              <Button variant="text" onClick={() => navigate('/app/dashboard')}>
                My Books
              </Button>
            )}
          </Stack>
          <Stack
            gridAutoFlow={'column'}
            justifySelf={'flex-end'}
            alignItems={'center'}
          >
            {!isAuthenticated && (
              <Button
                variant="text"
                style={{ marginRight: '3rem' }}
                onClick={() => navigate('/signin')}
              >
                Login
              </Button>
            )}
            <p style={{ margin: 0 }} data-testid="cart-counter">
              Cart - {cart.length}
            </p>
            <CartButton />
            {isAuthenticated && <ProfileButton />}
            <Menu />
          </Stack>
        </Stack>
      </Container>
    </Wrapper>
  )
}
