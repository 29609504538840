import styled from 'styled-components'
import { variant, color, space, typography, compose } from 'styled-system'
import { T } from '../types'

export const Body = styled.p<T>`
	font-weight: ${props => props.theme.typography.fontWeight.body};
	font-family: ${props => props.theme.typography.fontFamily.body};
	line-height: 1.5em;
	color: ${props => props.theme.typography.color.body};
	${compose(
		variant({
			variants: {
				xs: {
					fontSize: '12px'
				},
				sm: {
					fontSize: '14px'
				},
				md: {
					fontSize: '16px'
				}
			}
		}),
		space,
		color,
		typography
	)}
`

Body.defaultProps = {
	variant: 'md'
}

export default Body
