import styled from 'styled-components'
import {
  flex,
  flexbox,
  layout,
  space,
  FlexboxProps,
  FlexProps,
  LayoutProps,
  SpaceProps
} from 'styled-system'

type Props = FlexProps & LayoutProps & SpaceProps & FlexboxProps

const FlexContainer = styled.div<Props>`
  display: flex;
  ${flexbox}
  ${flex}   
  ${layout}
  ${space}
`

export default FlexContainer
