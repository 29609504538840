import create from 'zustand'
import { Magic } from 'magic-sdk'
import { navigate } from 'gatsby'

const isBrowser = typeof window !== 'undefined'

type StoreProps = {
  isAuthenticated: boolean
  user: null | { email: string; publicAdress: string; sessionToken: string }
  isMagicInitialised: boolean
  magic: null | Magic
  signOut: () => void
  signIn: ({
    email,
    redirectURI
  }: {
    email: string
    redirectURI?: string
  }) => Promise<{ [key: string]: any }>
  loginWithCredential: (string?: string) => void
  setSession: () => void
  updateUser: any
  refreshToken: () => void
}

const useStore = create<StoreProps>((set, get) => ({
  isAuthenticated: false,
  user: null,
  isMagicInitialised: false,
  magic: null,
  refreshToken: async () => {
    if (get().isMagicInitialised && (await get().magic.user.isLoggedIn())) {
      const userData = await Promise.all([
        get().magic.user.getMetadata(),
        get().magic.user.getIdToken()
      ])
      set({
        user: {
          email: userData[0].email,
          publicAdress: userData[0].publicAddress,
          sessionToken: userData[1]
        }
      })
    }
  },
  signIn: async options => {
    if (get().isMagicInitialised && (await get().magic.user.isLoggedIn())) {
      localStorage.setItem('isAuthenticated', 'true')
      get().updateUser(get().magic.user)
      return Promise.resolve(get().magic.user)
      //redirect user?
    } else {
      await get().magic.auth.loginWithMagicLink({ ...options })
      await get().updateUser(get().magic.user)
      return get().user
    }
  },
  signOut: async () => {
    if (isBrowser) {
      if (get().isMagicInitialised) {
        if (get().magic.user.isLoggedIn()) {
          localStorage.removeItem('isAuthenticated')
          set({ user: null, isAuthenticated: false })
          navigate('/signin')
          await get().magic.user.logout()
        }
      }
    }
  },
  loginWithCredential: async (string?: string) => {
    if (get().isMagicInitialised) {
      await get().magic.auth.loginWithCredential(string)
      await get().updateUser(get().magic.user)
    }
  },

  updateUser: async user => {
    if (isBrowser) {
      set({ isAuthenticated: true })
      localStorage.setItem('isAuthenticated', 'true')
      const userData = await Promise.all([
        user.getMetadata(),
        user.getIdToken()
      ])
      return set({
        user: {
          email: userData[0].email,
          publicAdress: userData[0].publicAddress,
          sessionToken: userData[1]
        }
      })
    }
  },
  setSession: async () => {
    const m = new Magic(process.env.GATSBY_MAGIC_PUBLISHABLE_KEY)
    //TODO:can we initialise this faster for someone who has never logged in?
    if (isBrowser) {
      if (JSON.parse(localStorage.getItem('isAuthenticated'))) {
        if (await m.user.isLoggedIn()) {
          await get().updateUser(m.user)

          //this means the user is logged in but app is unaware
        }
      }
    }
    //TODO: if user is not logged in (local storage out of date, log out)
    return set({ isMagicInitialised: true, magic: m })
  }
}))
export default useStore
