import React, { useState } from 'react'
import { Link } from 'gatsby'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { useMagic } from 'stores'
import { doesUserExist, createUser } from 'utils/auth'
import { Stack, Button, Body, CircularLoader, TextField } from 'components'
import { theme } from 'style'
import ModeSelector from './ModeSelector'

type Props = {
  mode: 'createAccount' | 'signIn'
  setMode: (_: 'createAccount' | 'signIn') => void
  showModeSelector?: boolean
  redirectURI?: string
}

export default function LoginForm({
  mode,
  setMode,
  showModeSelector = true
}: Props) {
  const { signIn } = useMagic()
  const [isLoading, setIsLoading] = useState(false)
  const schema = Yup.object().shape({
    email: Yup.string()
      .required('This field is required')
      .email("Hmm...this doesn't look like a valid email")
  })

  const changeMode = (mode: 'createAccount' | 'signIn') => {
    setMode(mode)
  }

  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={schema}
      onSubmit={async (values, actions) => {
        setIsLoading(true)
        const email = values.email.toLowerCase().trim()
        const userExists = await doesUserExist(email)
        if (userExists) {
          if (mode === 'createAccount') {
            actions.setFieldError(
              'email',
              'This user seems to exist already. Did you mean to sign in?'
            )
            setIsLoading(false)
          } else {
            signIn({
              email
            })
          }
        } else {
          if (mode === 'signIn') {
            actions.setFieldError(
              'email',
              "This user doesn't seem to exist yet. Did you mean to create an account? "
            )
            setIsLoading(false)
          } else {
            try {
              await signIn({
                email
              })
              await createUser(email)
            } catch (e) {
              actions.setFieldError(
                'email',
                'Something has gone wrong. Please try again later. '
              )
            }
          }
        }
      }}
      render={({ errors, handleChange, values }) => (
        <>
          {showModeSelector && (
            <ModeSelector changeMode={changeMode} mode={mode} />
          )}
          <Form data-testid="login-form">
            <Stack>
              <TextField
                className="input"
                label="Email Address"
                onChange={handleChange}
                name="email"
                placeholder="jane.smith@gmail.com"
                InputLabelProps={{
                  htmlFor: 'email'
                }}
                value={values.email}
                error={!!errors.email}
                inputProps={{
                  'data-testid': 'email-input'
                }}
                helperText={
                  errors.email
                    ? errors.email
                    : "Type in your email, and we'll send a magic link to your email"
                }
              />

              <Button type="submit" disabled={isLoading}>
                Get Magic Link
                {isLoading && <CircularLoader data-testid="circle-loader" />}
              </Button>
            </Stack>
            <Body mt="1.5rem">
              Having troubles logging in? Check our{' '}
              <Link to="/faq" style={{ color: theme.colors.blues.blue40 }}>
                FAQ!
              </Link>
            </Body>
          </Form>
        </>
      )}
    />
  )
}
