import styled from "styled-components"
import { compose, variant, color, space, typography } from "styled-system"
import { T } from "../types"

export const Heading = styled.h2<T>`
  font-weight: ${props => props.theme.typography.fontWeight.heading};
  font-family: ${props => props.theme.typography.fontFamily.heading};
  color: ${props => props.theme.typography.color.heading};
  ${compose(
    variant({
      variants: {
        xs: {
          fontSize: "1.25rem",
          lineHeight: "1.6em"
        },
        sm: {
          fontSize: "1.5rem",
          lineHeight: "1.33em"
        },
        md: {
          fontSize: "2.125rem",
          lineHeight: "1.17em"
        },
        lg: {
          fontSize: "3rem",
          lineHeight: "1.04em"
        },
        xl: {
          fontSize: "3.75rem",
          lineHeight: "1em"
        }
      }
    }),
    color,
    space,
    typography
  )}
`

Heading.defaultProps = {
  variant: "md"
}

export default Heading
