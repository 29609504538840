import { navigate } from 'gatsby'
import axios from 'axios'

export const doesUserExist = async (email: string) => {
  try {
    await axios.get(`/.netlify/functions/getUser`, {
      params: {
        email: email.toLowerCase()
      }
    })
    return true
  } catch (err) {
    if (err.response.status === 404) {
      return false
    } else {
      throw new Error(err)
    }
  }
}

export const createUser = async (email: string) => {
  try {
    const res = await axios.post('/.netlify/functions/createUser', {
      email: email
    })

    return res
  } catch (err) {
    console.log('error', err)
  }
}
