// import { BookType, ReviewType } from 'models'
import moment from 'moment'

export const convertTitle = title => {
  return title.toLowerCase().replace(/\s/g, '_')
}

export const itemExistsInCart = bookId => {
  const storage = global.localStorage || null
  if (storage) {
    let existingCart = JSON.parse(localStorage.getItem('cart'))
    //if no cart
    if (!existingCart) {
      return false
    } else {
      return existingCart.map(book => book.id).includes(bookId)
    }
  }
}

export const isCurrentPage = (pathname, link) => {
  return pathname === link ? true : false
}

export const generateRating = reviews => {
  return Number(
    (
      reviews.map(review => review.rating).reduce((val, acc) => val + acc) /
      reviews.length
    ).toFixed(1)
  )
}

export const generateSubscriptionExpiry = () => {
  const decFirst = moment()
    .startOf('year')
    .add(335, 'days')
  if (moment().isBefore(decFirst)) {
    return moment().endOf('year')
  } else {
    return moment()
      .add(1, 'year')
      .endOf('year')
  }
}

export type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<
  T,
  Exclude<keyof T, Keys>
> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>
  }[Keys]

export const getBookUrlFromTitle = (title: string) =>
  `https://writelabs.co/books/${title.toLowerCase().replace(/\s/g, '_')}`
