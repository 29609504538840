import styled from 'styled-components'
import { space } from 'styled-system'

import TextField from './TextField'

type Props = {
  center: boolean
}

//these components are basically here to support stripe elements.

const ErrorMsg = styled.p`
  margin-bottom: 0.5rem;
  color: #e81e61;
`

const InputGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 2rem;
  grid-column-gap: 1rem;
`

const Label = styled.label`
  padding-bottom: 1rem;
  color: #8b8e96;
  font-weight: bold;
`

const Wrapper = styled.form<Props>`
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.center ? 'center' : 'flex-start')};
  ${space}
`

const Form = {
  Wrapper,
  TextField,
  ErrorMsg,
  InputGroup,
  Label
}

export default Form
