import React, { useState } from 'react'
import { FaUserAlt } from 'react-icons/fa'

import Dropdown from '../../Dropdown'
import { Button } from './style'

export default function ProfileButton() {
  const [dropdown, setDropdown] = useState(false)

  const handleClick = () => {
    setDropdown(!dropdown)
  }

  return (
    <Button onClick={handleClick} data-testid="profile-btn">
      <FaUserAlt />
      {dropdown ? <Dropdown setDropdown={setDropdown} /> : null}
    </Button>
  )
}
