import React from 'react'

import { FiArrowLeft, FiArrowRight, FiLock } from 'react-icons/fi'
import { Component } from './style'

type Props = {
  textAlign: string
  chapterTitle: string
  chapterNum: number
  sectionNum: number
  to: string
  disabled?: boolean
}

export default function PageNavLink({
  textAlign,
  chapterTitle,
  chapterNum,
  sectionNum,
  to,
  disabled
}: Props) {
  if (textAlign === 'left') {
    return (
      <Component textAlign={textAlign} to={to} disabled={disabled}>
        <div>
          <p>Next</p>
          <h4>{chapterTitle}</h4>
          <p>
            Chapter {chapterNum}, Section {sectionNum}
          </p>
        </div>
        {!disabled ? <FiArrowRight /> : <FiLock />}
      </Component>
    )
  } else {
    return (
      <Component textAlign={textAlign} to={to} disabled={disabled}>
        {!disabled ? <FiArrowLeft /> : <FiLock />}
        <div>
          <p>Prev</p>
          <h4>{chapterTitle}</h4>
          <p>
            Chapter {chapterNum}, Section {sectionNum}
          </p>
        </div>
      </Component>
    )
  }
}
