import { getBookUrlFromTitle } from 'utils'
declare global {
  interface Window {
    _learnq?: (string | object)[]
  }
}

type Product = {
  title: string
  id: string
  price: number
}
export const trackRecentlyViewedItem = ({ title, id, price }: Product) => {
  const item = {
    Title: title,
    Id: id,
    Price: price,
    ItemUrl: getBookUrlFromTitle(title),
    Metadata: {
      Price: price
    }
  }
  window._learnq = window._learnq || []
  window._learnq.push(['track', 'Viewed Product', item])
  window._learnq.push(['trackViewedItem', { Title: item.Title, Id: item.Id }])
}

export const trackAddToCart = ({
  title,
  id,
  price,
  existingCart
}: Product & { existingCart: Product[] }) => {
  window._learnq = window._learnq || []
  window._learnq.push([
    'track',
    'Add To Cart',
    {
      $value: price,
      CheckoutURL: 'https://writelabs.co/cart',
      AddedItemTitle: title,
      AddItemId: id,
      AddedItemUrl: getBookUrlFromTitle(title),
      Items: existingCart.map(item => ({
        ItemUrl: getBookUrlFromTitle(title),
        Title: item.title,
        Price: item.price,
        ID: item.id
      }))
    }
  ])
}

export const trackDownloadSample = ({
  title,
  email,
  name
}: {
  title: string
  email: string
  name: string
}) => {
  window._learnq = window._learnq || []
  window._learnq.push([
    'identify',
    {
      $email: email,
      $first_name: name
    }
  ])
  window._learnq.push([
    'track',
    'Download Sample',
    {
      $email: email,
      $first_name: name,
      Title: title
    }
  ])
}

export const trackEvent = (eventName: string, eventData: any) => {
  //@ts-ignore
  window._learnq = window._learnq || []
  //@ts-ignore
  window._learnq.push(['track', eventName, eventData])
}
