import { BookType } from 'models'
import create from 'zustand'
import { combine } from 'zustand/middleware'
import { configurePersist } from 'zustand-persist'
import useNotificationStore from './useNotificationStore'
import { trackAddToCart } from 'hooks'

const windowGlobal = typeof window !== 'undefined' && window

const { persist } = configurePersist({
  storage:
    typeof window !== 'undefined'
      ? localStorage
      : { getItem: () => '', setItem: () => {}, removeItem: () => {} }
})

export type CartItem = Pick<BookType, 'contentful_id' | 'price' | 'title'>

const initialState = {
  cart: [] as CartItem[]
}

const useCartStore = create(
  persist(
    {
      key: 'cart'
    },
    combine(initialState, (set, get) => ({
      addToCart: (item: CartItem) => {
        if (
          get().cart.some(
            cartItem => cartItem.contentful_id === item.contentful_id
          )
        ) {
          useNotificationStore.getState().showNotification({
            message: '😕 Hmm...looks like this item is already in your cart'
          })
          return
        }
        set({
          cart: [item, ...get().cart]
        })
        useNotificationStore.getState().showNotification({
          message: '🎉 Added to your cart!',
          redirectUrl: '/cart'
        })
        trackAddToCart({
          title: item.title,
          price: item.price,
          id: item.contentful_id,
          existingCart: get().cart.map(item => ({
            title: item.title,
            id: item.contentful_id,
            price: item.price
          }))
        })
      },
      removeFromCart: (id: string) => {
        set({
          cart: get().cart.filter(cartItem => cartItem.contentful_id !== id)
        })
      },
      clearCart: () => set({ cart: [] })
    }))
  )
)
export default useCartStore
