import styled from 'styled-components'
import media from 'styled-media-query'

//TODO: shutters might need fixing

type ComponentProps = {
  isCollapsed: boolean
}

type ShutterProps = {
  isVisible: boolean
}

export const Component = styled.div<ComponentProps>`
  height: 100vh;
  position: sticky;
  top: 0;
  padding: 1rem 2rem 2rem 2rem;
  left: 0;
  /* transition: all ease-out 0.25s; */
  box-shadow: ${props =>
    !props.isCollapsed && '0 20px 40px rgba(0, 0, 0, 0.2)'};
  grid-template-rows: 2rem 80% 3rem;
  background: ${props => props.theme.sidebarbg};
  grid-row-gap: 1rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  box-sizing: border-box;
  z-index: 5;
  ${media.lessThan('small')`
        padding: 1rem;
    `}
`

export const Overlay = styled.div`
  background: black;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
`

export const ContentsList = styled.div`
  box-sizing: border-box;
  position: absolute;
  left: 5rem;
  height: 100vh;
  display: flex;
  padding: 5rem 3rem 10rem 3rem;
  background: ${props => props.theme.sidebarbg};
  top: 0;
  flex-direction: column;
  width: max-content;
  max-width: 50vw;
  z-index: 5;
  ${media.lessThan('small')`
        padding: 8rem 1rem 8rem 1rem;
        width: calc(100vw - 5rem);
        max-width: none;
    `}
`

const GradientShutter = styled.div<ShutterProps>`
  position: absolute;
  width: 85%;
  height: 4rem;
  z-index: 10;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: all ease 0.25s;
  pointer-events: none;
`
export const TopShutter = styled(GradientShutter)<ShutterProps>`
  background: linear-gradient(
    to bottom,
    ${props => props.theme.sidebarbg} 0%,
    rgba(255, 255, 255, 0) 100%
  );
  top: 7rem;
`

export const BottomShutter = styled(GradientShutter)<ShutterProps>`
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    ${props => props.theme.sidebarbg} 100%
  );
  bottom: 8rem;
  ${media.lessThan('small')`
        bottom: 9.9rem;
    `}
`

export const Divider = styled.div`
  width: 100%;
  border: 1px solid ${props => props.theme.colors.greys.grey60};
`
