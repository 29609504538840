import styled from "styled-components"
import { typography, layout, color } from "styled-system"
import { Link as Component } from "gatsby"

const Link = styled(Component)`
	cursor: pointer;
	transition: all ease 0.3s;
	color: ${props =>
    props.color ? props.color : props.theme.colors.oranges.orange40};
	&:hover {
		color: ${props => props.theme.colors.oranges.orange40};
		text-decoration: underline
	}
	${typography}
	${layout}
	${color}
`
export default Link
