import styled from 'styled-components'
import media from 'styled-media-query'
import ButtonBase from '@material-ui/core/ButtonBase'
import Img from 'gatsby-image'

export const Cover = styled(ButtonBase)`
&&& {
	width: 100%;
	border: 1px solid white;
	height: auto;
	border-radius: 10px;
	overflow: hidden;
	border: none;
	/* box-shadow: ${props => props.theme.shadows.twentyFourDp}; */
	box-shadow: -6px -6px 25px 0 rgba(255, 255, 255,0.83), 6px 6px 16px 0 rgba(130,146,180,0.50);
	transition: all ease-out 0.3s;
	mix-blend-mode: multiply;
	&:hover {
		box-shadow:  -1px -1px 10px 0 rgba(255, 255, 255,0.83), 1px 1px 8px 0 rgba(130,146,180,0.50)
	}
	${media.lessThan('small')`
        margin-bottom: 0.5rem;
    `}
}
`
export const CoverImg = styled(Img)`
	width: 100%;
`

export const Book = styled.div`
	display: flex;
	text-align: left;
	flex-direction: column;
`

export const Info = styled.div`
	display: grid;
	grid-template-columns: 1fr auto;
	grid-column-gap: 1rem;
	align-items: center;
`

export const List = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-row-gap: 2rem;
	grid-column-gap: 5rem;
	align-items: flex-start;
	margin-top: 2rem;
	${media.lessThan('small')`
    grid-template-columns: none;
    grid-row-gap: 2rem;
`}
`
