import React, { useState, useLayoutEffect, useEffect } from 'react'
import { navigate } from 'gatsby'
import { ThemeProvider } from 'styled-components'
import { IntercomProvider } from 'react-use-intercom'

import { convertTitle, canUserAccessBook } from 'utils'
import { Container, ReaderControls, ErrorBoundary } from 'components'
import SectionBody from './SectionBody'
import PageNavLink from './PageNavLink'
import SampleModal from './SampleModal'
import ContentsBar from './ContentsBar'
import { Wrapper, NavWrapper, lightTheme, darkTheme } from './style'
import { BookType, ChapterType, SectionType } from 'models'

type Props = {
  location: Location
  book: BookType
  section: SectionType
  chapter: ChapterType
}

export default function Reader({
  location,
  book,
  book: { title, chapters },
  section,
  chapter,
  chapter: { sections }
}: Props) {
  const [isContentsCollapsed, setIsContentsCollapsed] = useState(true)
  const [isSampleMode, setIsSampleMode] = useState(false)
  const [isDarkMode, setIsDarkMode] = useState(false)
  const [isSampleModalOpen, setIsSampleModalOpen] = useState(false)
  const [nextSection, setNextSection] = useState<SectionType | false>()
  const [prevSection, setPrevSection] = useState<SectionType | false>()
  const [nextChapter, setNextChapter] = useState<number>()
  const [prevChapter, setPrevChapter] = useState<number>()

  const sortByOrder = (arr: any) => {
    return arr.sort((a: any, b: any) => a.order - b.order)
  }

  useEffect(() => {
    ;(async () => {
      const hasUserPurchasedBook = await canUserAccessBook(book.contentful_id)
      if (!hasUserPurchasedBook) {
        if (!section.isAvailableAsSample) {
          navigate('/signin')
        }
        setIsSampleMode(true)
      } else {
        setIsSampleMode(false)
        setIsSampleModalOpen(false)
      }
    })()
  }, [])

  useEffect(() => {
    if (isSampleMode) {
      localStorage.getItem(`${location.pathname.split('/')[3]} - sample`) ===
      'true'
        ? setIsSampleModalOpen(false)
        : setIsSampleModalOpen(true)
    }
  }, [isSampleMode])

  useLayoutEffect(() => {
    if (typeof window !== 'undefined') {
      if (localStorage.getItem('isDarkMode')) {
        setIsDarkMode(JSON.parse(localStorage.getItem('isDarkMode')))
      } else {
        setIsDarkMode(false)
      }
    }
  }, [isDarkMode])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      //saving user's position
      localStorage.setItem(title, location.pathname)
    }

    if (section.order === 1 && chapter.order === 1) {
      //if first chapter and section of book, there won't be a previous section
      setPrevSection(false)
    } else {
      if (section.order > 1) {
        //if this is not the first section of a chapter, then decrement the section
        const prevSectionOrder = section.order - 1
        setPrevChapter(chapter.order)
        setPrevSection({
          order: prevSectionOrder,
          title: sortByOrder(sections)[section.order - 1].title,
          isAvailableAsSample: sortByOrder(sections)[section.order - 1]
            .isAvailableAsSample
        })
      } else if (section.order === 1 && chapter.order !== 1) {
        //if first section of the chapter, grab last section of previous chapter
        const prevSectionOrder = sortByOrder(chapters)[chapter.order - 2]
          .sections[
          sortByOrder(chapters)[chapter.order - 2].sections.length - 1
        ].order
        setPrevChapter(chapter.order - 1)
        setPrevSection({
          order: prevSectionOrder,
          title: sortByOrder(chapters)[chapter.order - 2].sections[
            sortByOrder(chapters)[chapter.order - 2].sections.length - 1
          ].title,
          isAvailableAsSample: sortByOrder(chapters)[chapter.order - 2]
            .sections[
            sortByOrder(chapters)[chapter.order - 2].sections.length - 1
          ].isAvailableAsSample
        })
      }
    }

    if (
      section.order === sections.length &&
      chapter.order === chapters.length
    ) {
      setNextSection(false)
    } else {
      if (section.order !== sections.length) {
        //if section isn't the last section, then increment upwards, set the chapter as current chapter
        const nextSectionOrder = section.order + 1
        setNextChapter(chapter.order)
        setNextSection({
          order: nextSectionOrder,
          title: sortByOrder(sections)[section.order].title,
          isAvailableAsSample: sortByOrder(sections)[section.order]
            .isAvailableAsSample
        })
      } else {
        //else get the next chapter and its first section
        setNextChapter(chapter.order + 1)
        setNextSection({
          order: 1,
          title: sortByOrder(sortByOrder(chapters)[chapter.order].sections)[0]
            .title,
          isAvailableAsSample: sortByOrder(
            sortByOrder(chapters)[chapter.order].sections
          )[0].isAvailableAsSample
        })
      }
    }
  }, [section, chapter])

  const toggleDarkMode = () => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('isDarkMode', JSON.stringify(!isDarkMode))
    }
    setIsDarkMode(!isDarkMode)
  }

  return (
    <IntercomProvider appId="t080vequ" autoBoot>
      <ErrorBoundary>
        <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
          <Wrapper data-testid="reader">
            <SampleModal
              isOpen={isSampleModalOpen}
              setIsOpen={setIsSampleModalOpen}
              location={location}
            />
            <ContentsBar
              isCollapsed={isContentsCollapsed}
              setIsCollapsed={setIsContentsCollapsed}
              chapters={chapters}
              sections={sections}
              chapter={chapter}
              section={section}
              bookTitle={title}
              isSampleMode={isSampleMode}
              nextSection={nextSection}
              prevSection={prevSection}
              nextChapterLink={
                nextSection &&
                `/app/book/${convertTitle(title)}/chapter${nextChapter}/${
                  nextSection.order
                }`
              }
              prevChapterLink={
                prevSection &&
                `/app/book/${convertTitle(title)}/chapter${prevChapter}/${
                  prevSection.order
                }`
              }
            />
            <div style={{ margin: '0 auto' }}>
              <ReaderControls
                darkMode={isDarkMode}
                toggleDarkMode={toggleDarkMode}
                isSampleMode={isSampleMode}
                title={title}
              />
              <Container maxWidth="md">
                <SectionBody section={section} chapter={chapter} />
                <NavWrapper>
                  {prevSection && (
                    <PageNavLink
                      to={`/app/book/${convertTitle(
                        title
                      )}/chapter${prevChapter}/${prevSection.order}`}
                      chapterTitle={prevSection.title}
                      chapterNum={prevChapter}
                      sectionNum={prevSection.order}
                      textAlign="right"
                      disabled={
                        isSampleMode && !prevSection.isAvailableAsSample
                      }
                    />
                  )}
                  {nextSection && (
                    <PageNavLink
                      chapterTitle={nextSection.title}
                      chapterNum={nextChapter}
                      sectionNum={nextSection.order}
                      to={`/app/book/${convertTitle(
                        title
                      )}/chapter${nextChapter}/${nextSection.order}`}
                      textAlign="left"
                      disabled={
                        isSampleMode && !nextSection.isAvailableAsSample
                      }
                    />
                  )}
                </NavWrapper>
              </Container>
            </div>
          </Wrapper>
        </ThemeProvider>
      </ErrorBoundary>
    </IntercomProvider>
  )
}
