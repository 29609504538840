import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'

import { BodyText } from './style'
import { Heading } from 'components'
import { ChapterType, SectionType } from 'models'

type Props = {
  chapter: ChapterType
  section: SectionType
}

const SectionBody = ({ chapter, section }: Props) => {
  const theme = useContext(ThemeContext)
  return (
    <div style={{ margin: '5rem 0' }}>
      <Heading variant="xs" textAlign="center" color={theme.subheading} m={0}>
        {chapter.title}
      </Heading>
      <Heading color="oranges.orange40" variant="sm" textAlign="center" my={3}>
        0{section.order}
      </Heading>
      <Heading textAlign="center" m={0} mb={4}>
        {section.title}
      </Heading>
      <BodyText source={section.content.content} escapeHtml={false} />
    </div>
  )
}
export default SectionBody
