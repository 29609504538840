import React, { useContext } from 'react'
import { animated, useSpring, config } from 'react-spring'
import { ThemeContext } from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

import { Component, Top, Bottom } from './style'

type Props = {
  isCollapsed: boolean
  setIsCollapsed: (_: boolean) => void
}

export default function BurgerMenu({ isCollapsed, setIsCollapsed }: Props) {
  const theme = useContext(ThemeContext)
  const topSpring = useSpring({
    transform: !isCollapsed
      ? 'rotate(315deg) translateX(-1px)'
      : 'rotate(0deg) translateX(0px)',
    config: config.gentle
  })

  const bottomSpring = useSpring({
    marginTop: !isCollapsed ? 0 : 8,
    backgroundColor: theme.color,
    transform: !isCollapsed
      ? 'rotate(225deg) translateX(1px)'
      : 'rotate(0deg) translateX(0px)',
    width: !isCollapsed ? 16 : 8,
    config: config.gentle
  })

  return (
    <Tooltip title="Contents">
      <IconButton
        style={{ height: '3rem', width: '3rem' }}
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <Component>
          <animated.div style={topSpring}>
            <Top />
          </animated.div>
          <animated.div style={bottomSpring}>
            <Bottom />
          </animated.div>
        </Component>
      </IconButton>
    </Tooltip>
  )
}
