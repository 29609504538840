import styled from "styled-components"

import Stack from "../Stack"
import { ComponentProps } from "./types"

export const Component = styled(Stack)<ComponentProps>`
  background-color: ${props => props.theme.colors.oranges.orange40};
  border-radius: 100px;
  grid-column-gap: ${props => props.theme.space[2]}px;
  grid-auto-flow: column;
  color: white;
  align-items: center;
  padding: ${props => (props.variant === "thin" ? "4px 8px" : "8px")};
  max-width: max-content;
`
