import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  z-index: 5;
  > p {
    margin: 0;
  }
`
