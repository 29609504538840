import React, { useState } from 'react'
import { navigate } from 'gatsby'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { Formik, Form } from 'formik'
import MuiButton from '@material-ui/core/Button'
import * as Yup from 'yup'

import { CircularLoader, Button, Stack, TextField, Body } from 'components'
import { useNotificationStore } from 'stores'
import { trackDownloadSample } from 'hooks'

const Schema = Yup.object().shape({
  name: Yup.string().required('This field is required'),
  email: Yup.string()
    .email("Hmm...this doesn't look like an valid email")
    .required('This field is required')
})

type Props = {
  isOpen: boolean
  setIsOpen: (_: boolean) => void
  location: Location
}

export default function SampleModal({ isOpen, setIsOpen, location }: Props) {
  const [isFormFilled, setIsFormFilled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleClose = () => {
    isFormFilled
      ? setIsOpen(false)
      : navigate(`/books/${location.pathname.split('/')[3]}`)
  }

  return (
    <Dialog
      open={isOpen}
      onExit={handleClose}
      disableBackdropClick
      data-testid="sample-modal"
    >
      <Formik
        initialValues={{
          name: '',
          email: '',
          book: location.pathname
            .split('/')[3]
            .split('_')
            .join(' ')
        }}
        onSubmit={async values => {
          try {
            setIsLoading(true)
            fetch('https://hooks.zapier.com/hooks/catch/1395652/o7n65uc/', {
              method: 'POST',
              body: JSON.stringify({
                email: values.email.trim().toLowerCase(),
                name: values.name,
                book: values.book
              })
            })
            trackDownloadSample({
              title: values.book,
              name: values.name,
              email: values.email.trim().toLowerCase()
            })
          } catch (e) {
            useNotificationStore.getState().showNotification({
              message: 'An error has occured. Please try again later.'
            })
          } finally {
            setIsLoading(false)
            setIsFormFilled(true)

            useNotificationStore.getState().showNotification({
              message: 'Thank you 😍 Enjoy your free preview'
            })
            localStorage.setItem(
              `${location.pathname.split('/')[3]} - sample`,
              'true'
            )
            setIsOpen(false)
            // startTour(136605)
          }
        }}
        validateOnChange={false}
        validationSchema={Schema}
        render={({
          handleSubmit,
          errors,
          handleChange,
          handleBlur,
          values
        }) => (
          <Form
            name={`Sample Form - ${location.pathname.split('/')[3]}`}
            data-testid="sample-modal-form"
          >
            <DialogContent>
              <h3>You're only one step away from reading your free sample!</h3>
              <p>Fill in the form below to unlock your sample.</p>
              <Stack mb={3}>
                <TextField
                  name="name"
                  label="Name"
                  data-testid="name-input"
                  error={!!errors.name}
                  helperText={errors.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                />
                <TextField
                  name="email"
                  data-testid="email-input"
                  label="Email Address"
                  error={!!errors.email}
                  helperText={errors.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                <Body variant="sm" color="greys.grey95">
                  By signing up, you agree to us sending you exclusive sample
                  essay topics, word banks, and advice. Don't worry, we hate
                  spam too!
                </Body>
              </Stack>
              <DialogActions>
                <MuiButton onClick={() => setIsOpen(false)}>Cancel</MuiButton>
                <Button
                  onClick={() => handleSubmit()}
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading && <CircularLoader />}
                  Get Sample
                </Button>
              </DialogActions>
            </DialogContent>
          </Form>
        )}
      />
    </Dialog>
  )
}
