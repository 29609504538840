import React from 'react'
import styled from 'styled-components'
import Component from '@material-ui/core/Container'
import { layout, flexbox, grid } from 'styled-system'

//TODO: Build own container

const Container = styled(
	({ gridTemplateColumns, alignItems, display, justifyContent, ...other }) => (
		<Component {...other} />
	)
)`
&& {
    ${layout}
    ${flexbox}
    ${grid}
}
`
export default Container
