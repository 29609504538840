import React from 'react'
import styled from 'styled-components'

import Stack from '../../../Stack'
import { ButtonBase } from '@material-ui/core'

type ChapterHeaderProps = {
  isChapterNumber: boolean
  isCurrent: boolean
  disabled: boolean
}

type ChevronProps = {
  isExpanded: boolean
}

export const ChapterHeader = styled(Stack)<ChapterHeaderProps>`
  padding-left: ${props => props.isChapterNumber && '1rem'};
  transition: all 0.3s ease-out;
  display: flex;
  border-left: 2px solid
    ${props =>
      props.isCurrent && props.isChapterNumber
        ? props.theme.colors.primaryRed
        : 'transparent'};
  filter: ${props => !props.isCurrent && 'saturate(0)'};
  opacity: ${props => !props.isCurrent && '0.6'};
  &:hover {
    font-weight: bold;
    filter: saturate(1);
    opacity: ${props => (props.disabled ? 0.6 : 1)};
  }
`

export const SectionLink = styled(({ ...other }) => (
  <ButtonBase {...other} component={Stack} />
))`
  && {
    display: grid;
    justify-content: flex-start;
    grid-column-start: 2;
    grid-template-columns: min-content 1fr min-content;
    margin-top: 0.5rem;
    margin-left: 3rem;
    &:hover {
      font-weight: ${props => (props.disabled ? 'normal' : 'bold')};
    }
  }
`

export const Component = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`

export const Chevron = styled.div<ChevronProps>`
  transform: ${props => (props.isExpanded ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: all ease 0.3s;
`
