/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import './layout.css'
import { Navbar, Footer } from 'components'

const Layout = (props) => {

  return (
    <StaticQuery
      query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
      render={data => (
        <>
          <Helmet>
            {props.location.pathname.match(/app\/book/) && (
              <script
                src="https://cdn.embedly.com/widgets/platform.js"
                type="text/javascript"
              />
            )}

            <title>{data.site.siteMetadata.title}</title>
          </Helmet>
          {!props.location.pathname.match(/app\/book/) && <Navbar />}
          <main>{props.children}</main>
          <Footer />
        </>
      )}
    />
  )
}


Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
