import styled from 'styled-components'
import { theme } from 'style'
import MuiRating from '@material-ui/lab/Rating'

import { withStyles } from '@material-ui/core/styles'

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const OverlayColor = styled.div`
  opacity: 0.3;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);

  background-color: ${theme.colors.greys.grey20};
`
export const Box = styled.div`
  background-color: white;
  border-radius: 10px;
  max-width: 70vw;
  box-shadow: ${theme.shadows[8]};
  z-index: 2;
  padding: 2rem;
  position: relative;
`

export const ExitButtonWrapper = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;
`

export const Rating = withStyles({
  iconFilled: {
    color: 'rgb(244, 201, 101)'
  },
  iconHover: {
    color: 'rgb(244, 201, 101)'
  }
})(MuiRating)

export default Overlay
