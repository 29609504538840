import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'

export const BodyText = styled(ReactMarkdown)`
  > blockquote {
    border-left: 3px solid #e81e61;
    padding-left: 1rem;
    font-style: italic;
  }
  > h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: Nunito, sans-serif;
    color: ${props => props.theme.heading};
    margin-bottom: 0;
  }
  > h1 {
    margin-top: 3rem;
  }
  > h2 {
    margin-top: 1em;
  }
  > a {
    text-decoration: underline;
    color: #e81e61;
  }
  > p {
    margin-top: 1rem;
    > img {
      margin: 2rem auto;
      max-width: 100%;
    }
    > a {
      text-decoration: underline;
      color: #e81e61;
    }
    img {
      margin: 1.5rem auto;
      max-width: 100%;
    }
  }
  > img {
    margin: 0 auto;
    max-width: 100%;
  }
`
