import React from 'react'

import { FiSun, FiMoon } from 'react-icons/fi'

import { Wrapper, Toggle, Icon } from './style'

type Props = {
  darkMode: boolean
  toggleDarkMode: (_: boolean) => void
}

export default function DarkModeSwitch({ darkMode, toggleDarkMode }: Props) {
  return (
    <Wrapper darkMode={darkMode} onClick={toggleDarkMode}>
      <Toggle darkMode={darkMode} />
      <Icon darkMode={darkMode}>{darkMode ? <FiMoon /> : <FiSun />}</Icon>
    </Wrapper>
  )
}
