import styled from 'styled-components'

import ButtonBase, { ButtonBaseProps } from '@material-ui/core/ButtonBase'

type ComponentProps = ButtonBaseProps & {
  component: React.ReactElement
  to: string
  href: string
}
type UnderlineProps = {
  isHover: boolean
}

export const Component = styled(ButtonBase)<ComponentProps>`
  &&& {
    position: relative;
    line-height: 1.25em;
    font-family: 'Merriweather', serif;
    color: ${props => props.theme.colors.greys.grey80};
    padding: 0.5rem 0;
    max-width: fit-content;
    font-size: 48px;
  }
`

export const Underline = styled.div<UnderlineProps>`
  width: ${props => (props.isHover ? '110%' : '0%')};
  position: absolute;
  bottom: 0;
  height: 3rem;
  left: 0;
  background: ${props => props.theme.colors.oranges.orange10};
  transition: all ease 0.3s;
  z-index: -1;
`
