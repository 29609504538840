import React, { useContext, useState } from 'react'
import { ThemeContext } from 'styled-components'
import { navigate } from 'gatsby'
import {
  Trail,
  Transition,
  animated,
  config
} from 'react-spring/renderprops.cjs'
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa'
import { FiLock } from 'react-icons/fi'
import VisibilitySensor from 'react-visibility-sensor'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

import {
  Component,
  ContentsList,
  Divider,
  Overlay,
  TopShutter,
  BottomShutter
} from './style'
import { Stack, Body } from 'components'
import BurgerMenu from './BurgerMenu'
import ChapterLink from './ChapterLink'
import { convertTitle } from 'utils'
import { ChapterType, SectionType } from 'models'

//TODO: There has got to be a way to refactor all of this

export type Props = {
  isCollapsed: boolean
  setIsCollapsed: (_: boolean) => void
  chapters: ChapterType[]
  chapter: ChapterType
  section: SectionType
  sections: SectionType[]
  nextChapterLink: string
  prevChapterLink: string
  bookTitle: string
  nextSection: SectionType | false
  prevSection: SectionType | false
  isSampleMode: boolean
}

export default function ContentsBar({
  isCollapsed,
  setIsCollapsed,
  chapters,
  section,
  sections,
  chapter,
  nextChapterLink,
  prevChapterLink,
  bookTitle,
  nextSection,
  prevSection,
  isSampleMode
}: Props) {
  const theme = useContext(ThemeContext)
  const sortedChapters = chapters.sort((a, b) => a.order - b.order)
  const [isListTopVisible, setIsListTopVisible] = useState(true)
  const [isListBottomVisible, setIsListBottomVisible] = useState(false)

  return (
    <>
      <Component isCollapsed={isCollapsed}>
        <BurgerMenu isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
        <Transition
          config={config.stiff}
          items={isCollapsed}
          from={{ opacity: 0 }}
          enter={{ opacity: 1 }}
          leave={{ opacity: 0 }}
        >
          {isCollapsed =>
            !isCollapsed &&
            (props => (
              <ContentsList style={props}>
                <TopShutter isVisible={!isListTopVisible} />
                <Body fontWeight="bold">Chapters</Body>
                <div
                  style={{
                    height: '100%',
                    overflow: 'scroll',
                    position: 'relative',
                    transform: 'translateZ(0)'
                  }}
                >
                  <VisibilitySensor onChange={e => setIsListTopVisible(e)}>
                    <div style={{ height: '1px' }} />
                  </VisibilitySensor>
                  <Trail
                    native
                    config={{ tension: 500, friction: 40 }}
                    items={sortedChapters}
                    keys={item => item.id}
                    from={{ opacity: 0, transform: 'translateX(-100%)' }}
                    to={{ opacity: 1, transform: 'translateX(0%)' }}
                  >
                    {item => props => (
                      <animated.div style={props}>
                        <ChapterLink
                          key={item.id}
                          chapter={item}
                          isCurrent={chapter.order === item.order}
                          bookTitle={bookTitle}
                          isSampleMode={isSampleMode}
                        />
                      </animated.div>
                    )}
                  </Trail>
                  <VisibilitySensor onChange={e => setIsListBottomVisible(e)}>
                    <div style={{ height: '1px' }} />
                  </VisibilitySensor>
                </div>
                <BottomShutter isVisible={!isListBottomVisible} />
              </ContentsList>
            ))
          }
        </Transition>
        <Stack alignItems="center" textAlign="center" gridRowGap={0}>
          {nextChapterLink && (
            <div>
              {isSampleMode && !nextSection.isAvailableAsSample ? (
                <IconButton disabled>
                  <FiLock fontSize={12} color={theme.arrowIcon} />
                </IconButton>
              ) : (
                <Tooltip title="Next Section">
                  <IconButton onClick={() => navigate(nextChapterLink)}>
                    <FaArrowRight fontSize={12} color={theme.arrowIcon} />
                  </IconButton>
                </Tooltip>
              )}
              <Body m={0} fontSize={12} color={theme.arrowIcon}>
                {section.order}
              </Body>
            </div>
          )}
          <Divider />
          {prevChapterLink && (
            <div>
              <Body m={0} fontSize={12} color={theme.arrowIcon}>
                {sections.length}
              </Body>
              {isSampleMode && !prevSection.isAvailableAsSample ? (
                <IconButton disabled>
                  <FiLock fontSize={12} color={theme.arrowIcon} />
                </IconButton>
              ) : (
                <Tooltip title="Previous Section">
                  <IconButton onClick={() => navigate(prevChapterLink)}>
                    <FaArrowLeft fontSize={12} color={theme.arrowIcon} />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          )}
        </Stack>
      </Component>
      <Transition
        items={isCollapsed}
        from={{ opacity: 0 }}
        enter={{ opacity: 0.5 }}
        leave={{ opacity: 0 }}
      >
        {isCollapsed =>
          !isCollapsed &&
          (props => (
            <Overlay style={props} onClick={() => setIsCollapsed(true)} />
          ))
        }
      </Transition>
    </>
  )
}
