import React, { useState } from 'react'
import {
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js'
import { navigate } from 'gatsby'
import { FaCcStripe } from 'react-icons/fa'
import axios from 'axios'
import { FiX } from 'react-icons/fi'
import { useMagic } from 'stores'

import {
  Box,
  Heading,
  Stack,
  Body,
  Button,
  FlexContainer,
  CircularLoader
} from 'components'
import {
  Overlay,
  OverlayColor,
  ExitButtonWrapper
} from 'components/pages/pro/components/Booklist/Modal/style'
import LoginForm from 'pages/signin/LoginForm'
import { generateSubscriptionExpiry } from 'utils'

export const Modal = ({
  setIsModalOpen
}: {
  setIsModalOpen: (_: boolean) => void
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [mode, setMode] = useState<'createAccount' | 'signIn'>('createAccount')
  const [purchaseError, setPurchaseError] = useState<null | ''>(null)
  const stripe = useStripe()
  const elements = useElements()
  const { isAuthenticated, user } = useMagic()

  const handleSubmit = async event => {
    event.preventDefault()
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    const { token, error } = await stripe.createToken(
      elements.getElement(CardNumberElement),
      {
        name: user.email
      }
    )
    try {
      setIsLoading(true)
      let req = await axios.post('/.netlify/functions/purchase-subscription', {
        email: user.email,
        token: token
      })
      navigate('/thankyou')
    } catch (err) {
      setPurchaseError(err.response.data)
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <Overlay>
      <OverlayColor />
      <Box
        style={{ zIndex: 8, position: 'relative' }}
        elevation={8}
        padding="2rem"
        rounded
        minWidth="50vw"
        maxWidth={['100vw', '75vw']}
      >
        <ExitButtonWrapper>
          <Button variant="icon" onClick={() => setIsModalOpen(false)}>
            <FiX />
          </Button>
        </ExitButtonWrapper>
        <Stack
          gridTemplateColumns={['1fr', '0.5fr 1fr']}
          gridColumnGap="2rem"
          gridRowGap="2rem"
        >
          <FlexContainer justifyContent="center" alignItems="center">
            <Box backgroundColor="blues.blue10" rounded p="2rem">
              <FlexContainer alignItems="start" flexDirection="column">
                <Body mb={0}>WriteLabs Pro</Body>
                <Heading m={0} mb="1rem">
                  <sup style={{ fontSize: '16px' }}>$</sup>99
                </Heading>
                <Body variant="sm" color="greys.grey80">
                  {`Enjoy full access until ${generateSubscriptionExpiry().format(
                    'MMMM Do, YYYY'
                  )}.`}
                </Body>
              </FlexContainer>
            </Box>
          </FlexContainer>
          <div>
            {!isAuthenticated ? (
              <>
                <Heading color="greys.grey90" mb="1rem" mt="0">
                  Alright, let's go!
                </Heading>
                {mode === 'createAccount' ? (
                  <Body>First, let's create an account.</Body>
                ) : (
                  <Body>Let's first get you logged in.</Body>
                )}
                {mode === 'createAccount' ? (
                  <Body
                    variant="sm"
                    color="greys.grey80"
                    onClick={() => setMode('signIn')}
                    style={{ cursor: 'pointer', fontStyle: 'italic' }}
                  >
                    I already have an account
                  </Body>
                ) : (
                  <Body
                    variant="sm"
                    color="greys.grey80"
                    onClick={() => setMode('createAccount')}
                    style={{ cursor: 'pointer', fontStyle: 'italic' }}
                  >
                    I don't have an account yet
                  </Body>
                )}

                <LoginForm
                  mode={mode}
                  setMode={setMode}
                  showModeSelector={false}
                  redirectURI={null}
                />
              </>
            ) : (
              <>
                <Heading mb="1rem">One last step</Heading>
                <Body>Fill in your payment details below</Body>
                <form onSubmit={handleSubmit} data-testid="stripe-form">
                  <Body color="greys.grey80">Card Number</Body>
                  <Stack mb="2rem" gridRowGap="1.5rem">
                    <CardNumberElement
                      options={{ style: { base: { fontSize: '20px' } } }}
                    />
                    <Stack gridTemplateColumns="repeat(2,1fr)">
                      <div>
                        <Body color="greys.grey80">Expiry Date</Body>
                        <CardExpiryElement
                          options={{ style: { base: { fontSize: '20px' } } }}
                        />
                      </div>
                      <div>
                        <Body color="greys.grey80">CVC</Body>
                        <CardCvcElement
                          options={{ style: { base: { fontSize: '20px' } } }}
                        />
                      </div>
                    </Stack>
                  </Stack>
                  {purchaseError && (
                    <Body variant="sm" color="oranges.orange50">
                      {purchaseError}
                    </Body>
                  )}
                  <Body variant="sm" mb="1.5rem">
                    <FaCcStripe style={{ paddingRight: '1rem' }} />
                    Secure payments with Stripe
                  </Body>
                  <Stack gridTemplateColumns="1fr max-content">
                    <div />
                    <Button disabled={!stripe || isLoading} type="submit">
                      {isLoading && <CircularLoader />}
                      Pay $99
                    </Button>
                  </Stack>
                </form>
              </>
            )}
          </div>
        </Stack>
      </Box>
    </Overlay>
  )
}

export default Modal
