import axios from 'axios'

import { useMagic } from 'stores'

// export const userHasValidMembership = async () => {
//   try {
//     const res = await axios({
//       method: 'get',
//       url: '/.netlify/functions/getSubscription',
//       headers: {
//         authorization: 'Bearer ' + getProfile().token
//       },
//       params: {
//         userId: getProfile().sub
//       }
//     })
//     if (res.data.subscriptionCollection.items.length > 0) {
//       return res.data.subscriptionCollection.items.some(subscription =>
//         moment().isBefore(moment(subscription.expiryDate))
//       )
//     } else {
//       return false
//     }
//   } catch (err) {
//     console.log(err)
//   }
// }

export const canUserAccessBook = async (id?: string) => {
  if (useMagic.getState().isAuthenticated) {
    const { email, sessionToken } = useMagic.getState().user
    try {
      //get a users purchases
      const res = await axios({
        params: {
          email: email
        },
        url: '/.netlify/functions/getUsersBooks',
        headers: {
          authorization: sessionToken
        },
        method: 'get'
      })
      return res.data.some(book => book.sys.id === id)
    } catch (err) {}
  } else {
    return false
  }
}
