import styled from 'styled-components'

import { theme } from 'style'

export const Wrapper = styled.div`
  background-color: white;
  border-radius: 10px;
  box-shadow: ${theme.shadows[8]};
  padding: 24px;
`
