import React, { useContext } from 'react'
import { navigate } from 'gatsby'
import { FiShoppingCart } from 'react-icons/fi'
import { Transition, config } from 'react-spring/renderprops.cjs'

import { Button } from 'components'
import Indicator from './Indicator'
import { useCartStore } from 'stores'

export default function CartButton() {
  const cart = useCartStore(state => state.cart)

  const active = !!cart.length
  return (
    <Button
      variant="icon"
      onClick={() => navigate('/cart')}
      color="#060b14"
      data-testid="cart-btn"
    >
      <FiShoppingCart />
      <Transition
        config={config.wobbly}
        items={active}
        from={{ transform: 'scale(0)' }}
        enter={{ transform: 'scale(1)' }}
        leave={{ transform: 'scale(0)' }}
      >
        {active =>
          active &&
          (style => <Indicator style={style} data-testid="cart-indicator" />)
        }
      </Transition>
    </Button>
  )
}
