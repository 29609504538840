import * as React from 'react'
import MuiButton from '@material-ui/core/Button'
import styled from 'styled-components'
import MuiIconButton from '@material-ui/core/IconButton'
import { space, color } from 'styled-system'

import { theme } from 'style'
import { ColourLuminance, fade } from '../../utils/styler'

export const Component = styled(
  ({ backgroundColor, color, innerRef, ...other }) => (
    <MuiButton {...other} ref={innerRef} />
  )
)`
  max-width: max-content;
  & {
    &.MuiButton-text {
      border-radius: 100px;
      padding: 12px 32px;
      color: ${props => props.theme.colors.blues.blue40};
      &:hover {
        background-color: ${props =>
          props.color
            ? fade(props.color, 0.1)
            : fade(props.theme.colors.blues.blue40, 0.1)};
      }
    }
    &.MuiButton-contained {
      border-radius: 100px;
      background-color: ${props => props.theme.colors.blues.blue40};
      color: white;
      padding: 12px 32px;
      box-shadow: 0px 1px 10px rgba(81, 97, 203, 0.12),
        0px 2px 4px rgba(81, 97, 203, 0.14), 0px 4px 4px rgba(81, 97, 203, 0.2);
      &:hover {
        background-color: ${props =>
          props.backgroundColor
            ? ColourLuminance(props.backgroundColor, 20)
            : ColourLuminance(props.theme.colors.blues.blue40, 20)};
      }
      ${({ fullWidth }) =>
        fullWidth &&
        `
      max-width: none;
      width: 100%;
    `}
    ${({ disabled }) =>
      disabled &&
      `
      background-color: ${theme.colors.greys.grey10};
      box-shadow: none;
      color: ${theme.colors.greys.grey40};

      `}
      ${space}
      ${color}
    }
    &.MuiButton-outlined {
      border-radius: 0;
      padding: 12px 32px;
      border-radius: 100px;
      border-color: ${props => props.theme.colors.blues.blue40};
      color: ${props => props.theme.colors.blues.blue40};
      &:hover {
        background-color: ${props => props.theme.colors.blues.blue40};
        color: white;
      }
      ${({ fullWidth }) =>
        fullWidth &&
        `
        max-width: auto;
        width: 100%;
      `}
      ${space}
      ${color}
    }
  }
`

export const IconButton = styled(
  ({ backgroundColor, color, innerRef, ...other }) => (
    <MuiIconButton {...other} ref={innerRef} />
  )
)`
  &&& {
    color: ${props => props.theme.colors.primary};
    &:hover {
      background-color: ${props =>
        props.color
          ? fade(props.color, 0.1)
          : fade(props.theme.colors.blues.blue40, 0.1)};
    }
    ${space}
    ${color}
  }
`
