import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'

import { Wrapper, Indicator, Link } from './style'
import { Body } from 'components'

type Props = {
  changeMode: (_: 'createAccount' | 'signIn') => void
  mode: 'createAccount' | 'signIn'
}

export default function ModeSelector({ changeMode, mode }: Props) {
  const theme = useContext(ThemeContext)
  return (
    <Wrapper data-testid="mode-selector">
      <Indicator mode={mode} />
      <Link active={mode === 'signIn'} onClick={() => changeMode('signIn')}>
        <Body
          m={0}
          color={mode !== 'createAccount' ? 'white' : theme.colors.greys.grey60}
        >
          Sign In
        </Body>
      </Link>
      <Link
        active={mode === 'createAccount'}
        onClick={() => changeMode('createAccount')}
      >
        <Body
          m={0}
          color={mode === 'createAccount' ? 'white' : theme.colors.greys.grey50}
        >
          Create Account
        </Body>
      </Link>
    </Wrapper>
  )
}
