import styled from 'styled-components'

const Line = styled.div`
	height: 2px;
	background-color: ${props => props.theme.color};
`

export const Component = styled.div`
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;
	cursor: pointer;
`

export const Top = styled(Line)`
	width: 16px;
`
export const Bottom = styled(Line)`
	width: 8px;
	/* margin-top: 4px; */
`
