import * as React from 'react'
import { FaStar } from 'react-icons/fa'

import { RatingBadgeProps } from './types'
import { Component } from './style'
import { generateRating } from 'utils'

const RatingBadge = ({ reviews, variant = 'normal' }: RatingBadgeProps) => (
  <Component data-testid="rating-badge" variant={variant}>
    <FaStar />
    {generateRating(reviews)}
  </Component>
)

export default RatingBadge
