import React, { useState, useEffect } from 'react'
import { FiCheckCircle } from 'react-icons/fi'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

import { Wrapper } from './style'
import { Body, Heading, Stack, Button, Box } from 'components'
import { theme } from 'style'
import Modal from './Modal/Modal'

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_KEY)

export const PricingTier = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  useEffect(() => {
    if (window !== undefined) {
      if (window.location.search) {
        new URLSearchParams(window.location.search).get('checkoutModal') &&
          setIsModalOpen(true)
        window.history.replaceState(null, null, window.location.pathname)
      }
    }
  })
  return (
    <Elements stripe={stripePromise}>
      <Wrapper id="pricing-tier">
        {isModalOpen && <Modal setIsModalOpen={setIsModalOpen} />}
        <Heading color={theme.colors.greys.grey70}>Pro</Heading>
        <Heading fontWeight="bold" variant="sm">
          $99
        </Heading>

        <Stack gridRowGap={3}>
          <Stack gridTemplateColumns="min-content 1fr">
            <FiCheckCircle style={{ marginTop: '0.25rem' }} />
            <Body m={0}>
              Unlimited access to all 20+ study guides (and counting!) until the
              31st of December 2021. Perfect for Year 12 students!
            </Body>
          </Stack>
          <Stack gridTemplateColumns="min-content 1fr">
            <FiCheckCircle style={{ marginTop: '0.25rem' }} />
            <Body m={0}>
              Tailored for the ATAR so you can smash the criteria and get 💯
              from your examiners.
            </Body>
          </Stack>
          <Stack gridTemplateColumns="min-content 1fr">
            <FiCheckCircle style={{ marginTop: '0.25rem' }} />
            <Body m={0}>
              We’ve put what you need for your assessments and exams in one
              place, so you can spend your precious time studying, rather than
              tediously searching for resources 😎.
            </Body>
          </Stack>
          <Stack gridTemplateColumns="min-content 1fr">
            <FiCheckCircle style={{ marginTop: '0.25rem' }} />
            <Body m={0}>
              Usually $25 per guide, get our entire library (RRP $575) for just
              $99!
            </Body>
          </Stack>
        </Stack>
        <Box mt="2rem">
          <Button fullWidth onClick={() => setIsModalOpen(true)}>
            Go Pro
          </Button>
        </Box>
      </Wrapper>
    </Elements>
  )
}

export default PricingTier
