import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import media from 'styled-media-query'
import ButtonBase from '@material-ui/core/ButtonBase'

export const Component = styled(({ textAlign, ...other }) => (
	<ButtonBase component={Link} {...other} />
))`
	display: grid;
	grid-template-columns: ${props =>
		props.textAlign === 'right' ? 'min-content 1fr' : '1fr min-content'};
	grid-template-gap: 0.5rem;
	align-items: center;
	padding: 1rem;
	margin-top: 2rem;
	transition: all ease 0.3s;
	font-weight: bold;
	font-size: 1rem;
	> div {
		> p,
		h4 {
			margin: 0.5rem;
		}
		> p {
			opacity: 0.5;
		}
	}
	&:hover {
		opacity: 1;
		border-left: ${props => props.textAlign === 'left' && '2px solid #e81e61'};
		border-right: ${props =>
		props.textAlign === 'right' && '2px solid #e81e61'};
	}
	${media.lessThan('small')`
		padding: 0;
	`}
`
