import create from 'zustand'
import { combine } from 'zustand/middleware'

const initialState = {
  redirectUrl: null as string | null,
  message: null as string | null
}
export const useNotificationStore = create(
  combine(initialState, (set, get) => ({
    showNotification: ({
      message,
      redirectUrl
    }: {
      message: string
      redirectUrl?: string
    }) => {
      set({ message, redirectUrl })
      setTimeout(() => {
        set({
          message: null,
          redirectUrl: null
        })
      }, 8000)
    },
    closeNotification: () => {
      set({ message: null, redirectUrl: null })
    }
  }))
)

export default useNotificationStore
