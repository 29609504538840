import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import { FiX } from 'react-icons/fi'

import DarkModeSwitch from './DarkModeSwitch'
import { Container } from 'components'
import { convertTitle } from 'utils'
import { Wrapper, ControlsWrapper } from './style'

type Props = {
  isSampleMode: boolean
  title: string
  darkMode: boolean
  toggleDarkMode: (_: boolean) => void
}

export default function ReaderControls({
  isSampleMode,
  title,
  darkMode,
  toggleDarkMode
}: Props) {
  const theme = useContext(ThemeContext)
  return (
    <Wrapper>
      <Container
        maxWidth="md"
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
      >
        <ControlsWrapper>
          <DarkModeSwitch darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
          <IconButton
            style={{ color: theme.color }}
            onClick={
              !isSampleMode
                ? () => navigate('/app/dashboard')
                : () => navigate(`/books/${convertTitle(title)}`)
            }
          >
            <FiX />
          </IconButton>
        </ControlsWrapper>
      </Container>
    </Wrapper>
  )
}

ReaderControls.propTypes = {
  darkMode: PropTypes.bool.isRequired,
  toggleDarkMode: PropTypes.func.isRequired
}
