import styled from 'styled-components'

type LineProps = {
  isHover: boolean
}

export const Component = styled.div`
  top: 0;
  display: flex;
  transition: all ease-in 0.3s;
`
const Line = styled.div`
  height: 2px;
  transition: all ease-out 0.3s;
  background-color: ${props => props.theme.colors.greys.grey90};
`
export const Top = styled(Line)<LineProps>`
  width: ${props => (props.isHover ? '16px' : '24px')};
`
export const Middle = styled(Line)<LineProps>`
  width: ${props => (props.isHover ? '24px' : '12px')};
`
export const Bottom = styled(Line)<LineProps>`
  width: ${props => (props.isHover ? '12px' : '16px')};
`

export const BurgerWrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 1rem;
`
export const Link = styled.a`
  font-weight: medium;
  font-size: 1.5rem;
`
export const SocialMediaWrapper = styled.div`
  display: grid;
  z-index: 2;
  position: fixed;
  grid-row-gap: 1rem;
  bottom: 2rem;
  right: 2rem;
`
export const MenuWrapper = styled.div`
  position: fixed;
  background: white;
  left: 0;
  height: 100vh;
  width: 100vw;
  top: 0;
  display: flex;
  align-items: center;
`
