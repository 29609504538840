import React from 'react'

import { Component, IconButton } from './style'
import { ButtonProps } from './types'

const Button = React.forwardRef(
  (
    {
      loading,
      forwardRef,
      children,
      variant = 'contained',
      ...rest
    }: ButtonProps,
    ref
  ) => {
    if (variant === 'icon') {
      return (
        <IconButton innerRef={ref} data-testid="icon-button" {...rest}>
          {children}
        </IconButton>
      )
    }

    return (
      <Component {...rest} variant={variant} innerRef={ref}>
        {children}
      </Component>
    )
  }
)

export default Button
