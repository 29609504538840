import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'

import { Body, Stack, RatingBadge } from 'components'
import { convertTitle } from 'utils/helpers'
import { Cover, CoverImg, Book, List } from './style'
import { BookType } from 'models'

export default function BookList() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulBook(filter: { deprecated: { ne: true } }) {
        nodes {
          title
          contentful_id
          price
          description {
            description
          }
          reviews {
            rating
          }
          cover {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  `)
  return (
    <List>
      {data.allContentfulBook.nodes.map((book: Partial<BookType>) => {
        return (
          <Book key={book.contentful_id}>
            <>
              <Link to={`/books/${convertTitle(book.title)}`}>
                <Cover>
                  <CoverImg fluid={book.cover.fluid} />
                </Cover>
              </Link>
              <Link to={`/books/${convertTitle(book.title)}`}>
                <Stack alignItems="center" mt={3}>
                  <Stack
                    gridTemplateColumns="1fr min-content"
                    alignItems="center"
                  >
                    <Body m={0}>
                      <strong>{book.title}</strong>
                    </Body>
                    {book.reviews && (
                      <RatingBadge variant="thin" reviews={book.reviews} />
                    )}
                  </Stack>
                  <Body color="greys.grey70">$ {book.price}</Body>
                </Stack>
              </Link>
            </>
          </Book>
        )
      })}
    </List>
  )
}
