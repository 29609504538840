import styled from 'styled-components'
import media from 'styled-media-query'
import { theme } from 'style'

export const Wrapper = styled.div`
  background: ${props => props.theme.bg};
  color: ${props => props.theme.color};
  position: relative;
  height: 100%;
  /* transition: all ease-out 0.3s; */
  display: flex;
`

export const lightTheme = {
  bg: 'white',
  subheading: theme.colors.greys.grey40,
  heading: theme.colors.greys.grey80,
  color: 'black',
  sidebarbg: 'white',

  arrowIcon: theme.colors.greys.grey60
}

export const darkTheme = {
  bg: '#1E1E1E',
  color: 'white',
  subheading: theme.colors.greys.grey20,
  heading: theme.colors.greys.grey10,
  sidebarbg: 'rgb(36,36,38, 1)',
  arrowIcon: 'white',
  typography: {
    fontFamily: {
      body: 'Muli, sans-serif',
      heading: 'Nunito, sans-serif'
    },
    fontWeight: {
      body: 400,
      heading: 200,
      bold: 700
    },
    color: {
      body: 'white',
      heading: 'white'
    }
  }
}

export const NavWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 5rem;
  ${media.lessThan('medium')`
        grid-column-gap: 1rem;
    `}
  padding-bottom: 3rem;
`
