import React from 'react'

import { RingSpinner } from 'react-spinners-kit'

import { theme } from 'style'
import { Stack } from 'components'
import { Wrapper } from './style'
import { Body } from 'components'

export default function CallbackLoader() {
  return (
    <Wrapper>
      <Stack
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        style={{ display: 'flex' }}
      >
        <div style={{ margin: '0 auto' }}>
          <RingSpinner color={theme.colors.blues.blue40} />
          {/* <Body color="blues.blue40">Loading</Body> */}
        </div>
      </Stack>
    </Wrapper>
  )
}
